import { useContext } from 'react';

import Table from '../components/Tables/Default';
import Card from '../components/ui/Card';
import CustomerContext from '../context/customers/customerContext';
import { convertDate } from '../helpers';

const Customers = () => {
	const customerContext = useContext(CustomerContext);
	const { customers, filteredCustomers, getCustomers, customerLoading } =
		customerContext;

	return (
		<div>
			<Card className='flex flex-col h-full'>
				<div className='flex items-center justify-between w-full p-4 py-4'>
					<h1 className='text-xl font-semibold text-opacity-80 text-gold-700'>
						Danso Abeam
					</h1>
				</div>
			</Card>
		</div>
	);
};

export default Customers;
