import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Settings from './pages/Settings';
import AppLayout from './components/layout/AppLayout';
import Debits from './pages/Transactions/Debits';
import Credits from './pages/Transactions/Credits';
import Balances from './pages/Transactions/Balances';
import RecentTransactions from './pages/Transactions/RecentTransactions';
import TransactionsLayout from './components/layout/TransactionsLayout';
import Customers from './pages/Customers';
import CustomerDetail from './pages/CustomerDetail';
import AuthState from 'context/auth/AuthState';
import CustomerState from 'context/customers/CustomerState';
import TransactionState from 'context/transactions/TransactionState';
import PrivateRoute from './components/routing/PrivateRoute';
import PasswordForm from './components/settings/PasswordForm';

function App() {
	return (
		<AuthState>
			<CustomerState>
				<TransactionState>
					<ToastContainer />

					<Routes>
						<Route path='/' element={<Home />} />

						<Route path='/app' element={<PrivateRoute component={AppLayout} />}>
							<Route path='dashboard' element={<Dashboard />} />

							<Route path='transactions' element={<TransactionsLayout />}>
								<Route path='all' element={<RecentTransactions />} />
								<Route path='credits' element={<Credits />} />
								<Route path='debits' element={<Debits />} />
								<Route path='balances' element={<Balances />} />
							</Route>

							<Route path='customers' element={<Customers />}>
								<Route path=':id' element={<CustomerDetail />} />
							</Route>

							<Route path='settings' element={<PasswordForm />} />
						</Route>
					</Routes>
				</TransactionState>
			</CustomerState>
		</AuthState>
	);
}

export default App;
