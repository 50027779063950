// * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// > REQUIREMENTS
// !  Dependencies
//    npm i @headlessui/react
//    npm i react-icons (optional bcos u can use any icon library)

// >  Props
// *  children (any) - Child content of the modal
// *  isModalOpen (bool: state)
// *  setModalOpen (func: state)
// *  title (string) - Title of the modal
// *  titleStyles (string) - Modal title styles
// *  containerStyle (string) - styles for the modal container
// *  closeButtonStyle (string)
// *  modalPosition (string) - vertical position of the modal on the viewport (values: top, bottom, center)
// *  closeOnOutsideClick (bool) - should modal close when click outside?
// * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { VscClose } from 'react-icons/vsc';
import PropTypes from 'prop-types';

const Modal = (props) => {
	const {
		children,
		isModalOpen,
		setModalOpen,
		onCloseModal,
		title,
		titleStyles,
		containerStyle,
		closeButtonStyle,
		modalPosition,
		closeOnOutsideClick,
	} = props;

	useEffect(() => {
		const closeModalEvent = document.addEventListener('closeModal', () =>
			setModalOpen(false)
		);

		return () => {
			document.removeEventListener('closeModal', closeModalEvent);
		};
	}, []);

	return (
		<Transition appear show={isModalOpen} as={Fragment}>
			<Dialog
				as='div'
				className='fixed inset-0 z-[10000] overflow-y-auto'
				onClose={() => setModalOpen(false)}
			>
				<div className='min-h-screen px-4 text-center'>
					<Transition.Child
						as={'div'}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<Dialog.Overlay
							className={`fixed inset-0 bg-black opacity-30 ${
								!closeOnOutsideClick ? 'pointer-events-none' : ''
							}`}
						/>
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className='inline-block h-screen align-middle'
						aria-hidden='true'
					>
						&#8203;
					</span>

					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						enterTo='opacity-100 translate-y-0 sm:scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 translate-y-0 sm:scale-100'
						leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
					>
						<div
							className={`inline-block relative overflow-hidden transition-all transform m-6 shadow-xl ${
								containerStyle
									? containerStyle
									: 'w-full bg-white p-6 rounded-xl'
							} 
              ${
								modalPosition === 'top'
									? 'align-top'
									: modalPosition === 'center'
									? 'align-middle'
									: modalPosition === 'bottom'
									? 'align-bottom'
									: 'align-text-bottom'
							}
              `}
						>
							{title && (
								<Dialog.Title
									as='h2'
									className={`${
										titleStyles
											? titleStyles
											: 'pb-2 mb-2 text-lg font-semibold uppercase border-b border-gray-300 border-opacity-100 text-primary-600'
									}`}
								>
									{title}
								</Dialog.Title>
							)}

							<button
								className='absolute z-20 flex items-center justify-center rounded-lg top-4 right-4'
								onClick={() => {
									onCloseModal();
									setModalOpen(false);
								}}
							>
								<VscClose
									className={`${
										closeButtonStyle
											? closeButtonStyle
											: 'text-2xl text-red-600'
									}`}
								/>
							</button>

							{children}
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

Modal.defaultProps = {
	modalPosition: 'align-text-bottom',
};

Modal.propTypes = {
	modalposition: PropTypes.string,
	onCloseModal: PropTypes.func,
};

export default Modal;
