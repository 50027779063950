import React, { useEffect, useContext, useReducer } from 'react';
import AuthContext from './authContext';
import authReducer from './authReducer';
import { auth } from '../types';
import exceptionalAPI from 'context/exceptionalAPI';
import { toast } from 'react-toastify';
import setAuthToken from 'utils/setAuthToken';

// Create a custom hook to use the auth context

export const useAuth = () => {
	const { state, dispatch } = useContext(AuthContext);
	return [state, dispatch];
};

// Load User
export const loadUser = async (dispatch) => {
	try {
		const res = await exceptionalAPI.get('/admin/auth/me');

		dispatch({
			type: auth.USER_LOADED,
			payload: res.data,
		});
	} catch (err) {
		dispatch({ type: auth.AUTH_ERROR });
	}
};

// Login User
export const login = async (dispatch, formData) => {
	const id = toast.loading('Authenticating');

	try {
		const res = await exceptionalAPI.post('/admin/auth/login', formData, {
			headers: {
				'Content-Type': 'application/json',
			},
		});

		dispatch({
			type: auth.LOGIN_SUCCESS,
			payload: res.data,
		});

		toast.dismiss(id);
	} catch (err) {
		dispatch({ type: auth.AUTH_ERROR });
		toast.update(id, {
			render: err?.response?.data?.error || 'Login Failed',
			type: 'error',
			isLoading: false,
			autoClose: 3000,
		});
	}
};

export const changePassword = async (dispatch, formValues) => {
	const id = toast.loading('Changing Password');

	const token = localStorage.getItem('token');

	if (!token) return logout(dispatch);

	try {
		const { data } = await exceptionalAPI.put(
			`/admin/auth/password-reset/${token}`,
			formValues,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		toast.update(id, {
			render: data?.success,
			type: 'success',
			isLoading: false,
			autoClose: 3000,
		});
	} catch (err) {
		toast.update(id, {
			render: 'Password reset failed.',
			type: 'error',
			isLoading: false,
			autoClose: 3000,
		});
	}
};

// Logout
export const logout = (dispatch) => dispatch({ type: auth.LOGOUT });

// Clear Errors
export const clearErrors = (dispatch) => dispatch({ type: auth.CLEAR_ERRORS });

const AuthState = (props) => {
	// Create initial state
	const initialState = {
		token: localStorage.getItem('token'),
		isAuthenticated: null,
		authLoading: true,
		user: null,
		authError: null,
	};

	// Init Reducer
	const [state, dispatch] = useReducer(authReducer, initialState);

	// set token on initial app loading
	setAuthToken(state.token);

	// load user on first run or refresh
	if (state.authLoading) {
		loadUser(dispatch);
	}

	// 'watch' state.token and set headers and local storage on any change
	useEffect(() => {
		setAuthToken(state.token);
	}, [state.token]);

	return (
		<AuthContext.Provider value={{ state: state, dispatch }}>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthState;
