import { transactions } from './../types';

export default (state, { type, payload }) => {
	switch (type) {
		case transactions.FETCH_TRANSACTIONS:
			return {
				...state,
				transactions: payload,
				debits: {
					data: payload?.data?.filter(
						(transaction) => transaction.trans_status == 'DEBIT'
					),
				},
				credits: {
					data: payload?.data?.filter(
						(transaction) => transaction.trans_status == 'CREDIT'
					),
				},
				transactionsLoading: false,
			};

		case transactions.TRANSACTION_STATS:
			return {
				...state,
				transactionStatistics: payload.data[0],
				transactionsLoading: false,
			};
		case transactions.FETCH_TRANSACTIONS_SUMMARY:
			return {
				...state,
				transactionSummary: payload,
				transactionsLoading: false,
			};
		case transactions.FETCH_CUSTOMER_TRANSACTIONS:
			return {
				...state,
				customerTransactions: payload,
				transactionsLoading: false,
			};

		case transactions.ADD_TRANSACTION:
			return {
				...state,
				// transactions: [payload, ...state.transactions],
				transactionsLoading: false,
			};

		case transactions.UPDATE_TRANSACTION:
			return {
				...state,
				transactions: state.transactions.map((transaction) =>
					transaction._id === payload._id ? payload : transaction
				),
				transactionsLoading: false,
			};

		case transactions.DELETE_TRANSACTION:
			return {
				...state,
				transactions: state.transactions.filter(
					(transaction) => transaction._id !== payload
				),
				transactionsLoading: false,
			};

		case transactions.SET_CURRENT_TRANSACTION:
			return {
				...state,
				currentTransaction: payload,
				transactionsLoading: false,
			};

		case transactions.CLEAR_CURRENT_TRANSACTION:
			return {
				...state,
				currentTransaction: null,
				transactionsLoading: false,
			};

		case transactions.CLEAR_CURRENT_CUSTOMER_TRANSACTIONS:
			return {
				...state,
				customerTransactions: null,
				transactionsLoading: false,
			};

		case transactions.FILTER_TRANSACTIONS:
			return {
				...state,
				filteredTransactions: state.transactions.filter((transaction) => {
					const regex = RegExp(`${payload}`, 'gi');
					return (
						transaction.name.match(regex) || transaction.email.match(regex)
					);
				}),
				transactionsLoading: false,
			};

		case transactions.CLEAR_TRANSACTIONS:
			return {
				...state,
				transactions: null,
				filteredTransactions: null,
				transactionsError: null,
				currentTransaction: null,
				transactionsLoading: false,
			};

		case transactions.CLEAR_FILTERED_TRANSACTIONS:
			return {
				...state,
				filteredTransactions: null,
				transactionsLoading: false,
			};

		case transactions.TRANSACTION_ERROR:
			return {
				...state,
				transactionsError: payload,
				transactionsLoading: false,
			};

		default:
			break;
	}
};
