import { Navigate, useLocation, Outlet } from 'react-router-dom';

import TransactionsNavigator from '../ui/TransactionsNavigator';

const TransactionsLayout = () => {
	const { pathname } = useLocation();

	return (
		<div className='min-h-full'>
			{pathname === '/app/transactions' && (
				<Navigate to='/app/transactions/all' />
			)}

			<div className='py-2'>
				<div className='flex justify-between'>
					<div></div>

					<TransactionsNavigator />
				</div>

				<div className='py-8'>
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default TransactionsLayout;
