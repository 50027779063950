import Card from './Card';

const EmptyResourceIndicator = ({
	message,
	orientation,
	containerClassName,
	removeIcon,
}) => {
	return (
		<Card className={`h-full w-full ${containerClassName}`}>
			<div
				className={`flex items-center justify-center h-full w-full p-4 ${
					orientation === 'landscape' ? 'flex-row gap-8' : 'flex-col'
				}`}
			>
				{!removeIcon && (
					<svg
						width='242'
						height='136'
						viewBox='0 0 242 136'
						className='w-40 h-40'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M49.8054 72.1537C49.8054 72.1537 23.9999 48.1951 57.9397 33.0945C91.8795 17.994 122.734 36.2707 122.734 36.2707C122.734 36.2707 170.137 24.8837 192.857 44.358C215.577 63.8324 188.931 89.861 188.931 89.861C188.931 89.861 225.956 101.659 223.431 121.413C220.907 141.167 118.246 128.545 118.246 128.545C118.246 128.545 36.0612 145.952 20.9145 117.847C5.7678 89.7413 49.8054 72.1537 49.8054 72.1537Z'
							fill='url(#paint0_linear_1600_706)'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M56.5049 63.8324C42.6978 63.8324 31.5049 75.0253 31.5049 88.8324V96.8902C31.5049 100.867 34.7284 104.09 38.7049 104.09H39.7228L42.8844 107.144C43.1529 107.403 43.5886 107.403 43.857 107.144L47.0187 104.09H99.7101C103.687 104.09 106.91 100.867 106.91 96.8902V88.8324C106.91 75.0253 95.7172 63.8324 81.9101 63.8324H56.5049Z'
							fill='#D0E4F0'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M54.6104 63.8324C40.8032 63.8324 29.6104 75.0253 29.6104 88.8324V95.0902C29.6104 99.7842 33.204 103.639 37.7901 104.053L40.9899 107.144C41.2584 107.403 41.6941 107.403 41.9625 107.144L45.1241 104.09H96.0156C100.986 104.09 105.016 100.061 105.016 95.0902V88.8324C105.016 75.0253 93.8227 63.8324 80.0156 63.8324H54.6104Z'
							fill='url(#paint1_linear_1600_706)'
						/>
						<rect
							x='80.1333'
							y='90.1829'
							width='21.5985'
							height='9.51548'
							fill='#83AABA'
						/>
						<rect
							x='78.6177'
							y='90.1829'
							width='21.5985'
							height='9.51548'
							fill='#ABD4D2'
						/>
						<rect
							x='36.8105'
							y='73.7138'
							width='62.143'
							height='4.02578'
							fill='#C8D8E6'
						/>
						<rect
							x='36.8105'
							y='81.7654'
							width='46.2283'
							height='2.92784'
							fill='#D6F1F1'
						/>
						<rect
							x='35.2944'
							y='73.7138'
							width='62.143'
							height='4.02578'
							fill='#D6E0EA'
						/>
						<rect
							x='35.2944'
							y='81.7654'
							width='46.2283'
							height='2.92784'
							fill='#E1F1F6'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M83.8983 11.36C80.5183 11.36 77.7783 14.1 77.7783 17.48V33.3472C77.7783 36.7272 80.5183 39.4672 83.8983 39.4672H127.907L130.443 41.9338C130.672 42.156 131.044 42.156 131.273 41.9338L133.809 39.4672H134.722C138.102 39.4672 140.842 36.7272 140.842 33.3472V17.48C140.842 14.1 138.102 11.36 134.722 11.36H83.8983Z'
							fill='#D0E4F0'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M83.8224 11.36C79.5974 11.36 76.1724 14.785 76.1724 19.01V31.8172C76.1724 36.0422 79.5974 39.4672 83.8224 39.4672H126.391L128.928 41.9338C129.156 42.156 129.529 42.156 129.757 41.9338L132.309 39.4524C136.31 39.2042 139.478 35.8807 139.478 31.8172V19.01C139.478 14.785 136.053 11.36 131.828 11.36H83.8224Z'
							fill='url(#paint2_linear_1600_706)'
						/>
						<rect
							x='116.085'
							y='27.8607'
							width='18.3038'
							height='7.23431'
							fill='#83AABA'
						/>
						<rect
							x='114.57'
							y='27.8607'
							width='18.3038'
							height='7.23431'
							fill='#ABD4D2'
						/>
						<rect
							x='81.9922'
							y='15.7322'
							width='47.9922'
							height='3.43533'
							fill='#C8D8E6'
						/>
						<rect
							x='82.0615'
							y='22.6028'
							width='37.4184'
							height='2.49842'
							fill='#D6F1F1'
						/>
						<rect
							x='80.9893'
							y='15.7322'
							width='47.9922'
							height='3.43533'
							fill='#D6E0EA'
						/>
						<rect
							x='80.9893'
							y='22.6028'
							width='37.4184'
							height='2.49842'
							fill='#E1F1F6'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M131.274 50.3086C127.298 50.3086 124.074 53.5322 124.074 57.5086V86.7667C124.074 90.7432 127.298 93.9667 131.274 93.9667H153.102L153.079 93.9896L158.324 99.0507C158.592 99.3097 159.027 99.3097 159.296 99.0508L164.541 93.9896L164.517 93.9667H186.877C190.853 93.9667 194.077 90.7432 194.077 86.7667V57.5086C194.077 53.5321 190.853 50.3086 186.877 50.3086H131.274Z'
							fill='#D0E4F0'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M131.173 50.3085C126.203 50.3085 122.173 54.338 122.173 59.3085V84.9667C122.173 89.9372 126.203 93.9667 131.173 93.9667H152.045L156.808 98.5627C157.076 98.8217 157.512 98.8217 157.78 98.5628L162.543 93.9667H183.176C188.147 93.9667 192.176 89.9372 192.176 84.9667V59.3085C192.176 54.338 188.147 50.3085 183.176 50.3085H131.173Z'
							fill='url(#paint3_linear_1600_706)'
						/>
						<rect
							x='166.128'
							y='80.075'
							width='21.6743'
							height='9.54006'
							fill='#83AABA'
						/>
						<rect
							x='164.612'
							y='80.075'
							width='21.6743'
							height='9.54006'
							fill='#ABD4D2'
						/>
						<rect
							x='129.393'
							y='57.2801'
							width='49.4325'
							height='4.03618'
							fill='#C8D8E6'
						/>
						<rect
							x='129.393'
							y='65.3526'
							width='56.277'
							height='2.9354'
							fill='#D6F1F1'
						/>
						<rect
							x='129.393'
							y='72.3241'
							width='38.7855'
							height='2.9354'
							fill='#D6F1F1'
						/>
						<rect
							x='127.877'
							y='57.2801'
							width='49.4325'
							height='4.03618'
							fill='#D6E0EA'
						/>
						<rect
							x='127.877'
							y='65.3526'
							width='56.277'
							height='2.9354'
							fill='#E1F1F6'
						/>
						<rect
							x='127.877'
							y='72.3241'
							width='38.7855'
							height='2.9354'
							fill='#E1F1F6'
						/>
						<ellipse
							cx='158.638'
							cy='116.846'
							rx='34.2203'
							ry='2.70477'
							fill='#DCEBF3'
						/>
						<ellipse
							cx='68.3184'
							cy='122.255'
							rx='34.2203'
							ry='2.70477'
							fill='#DCEBF3'
						/>
						<ellipse
							cx='194.541'
							cy='9.7371'
							rx='7.29285'
							ry='7.03239'
							fill='#E6F1F6'
							stroke='#E6F1F6'
							strokeWidth='4'
						/>
						<ellipse
							cx='179.394'
							cy='24.3429'
							rx='3.92692'
							ry='3.78667'
							fill='#E6F1F6'
							stroke='#E6F1F6'
							strokeWidth='4'
						/>
						<ellipse
							cx='30.1712'
							cy='49.2267'
							rx='2.80494'
							ry='2.70477'
							fill='#E6F1F6'
							stroke='#E6F1F6'
							strokeWidth='4'
						/>
						<ellipse
							cx='226.517'
							cy='98.4535'
							rx='2.80494'
							ry='2.70477'
							fill='#E6F1F6'
						/>
						<ellipse
							cx='12.7805'
							cy='124.96'
							rx='2.80494'
							ry='2.70477'
							fill='#E6F1F6'
						/>
						<rect
							x='219.602'
							y='52.7633'
							width='2.0494'
							height='5.49577'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<rect
							x='219.602'
							y='63.4552'
							width='2.0494'
							height='5.49577'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<path
							d='M229.011 60.1102V59.8602H228.761H223.58H223.33V60.1102V61.6042V61.8542H223.58H228.761H229.011V61.6042V60.1102Z'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<path
							d='M217.923 60.1102V59.8602H217.673H212.493H212.243V60.1102V61.6042V61.8542H212.493H217.673H217.923V61.6042V60.1102Z'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<rect
							x='146.265'
							y='0.25'
							width='0.621977'
							height='2.74572'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<rect
							x='146.265'
							y='8.36432'
							width='0.621977'
							height='2.74572'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<rect
							x='152.217'
							y='5.38904'
							width='0.581906'
							height='2.86593'
							transform='rotate(90 152.217 5.38904)'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<rect
							x='143.802'
							y='5.38904'
							width='0.581906'
							height='2.86593'
							transform='rotate(90 143.802 5.38904)'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<rect
							y='0.347068'
							width='0.602124'
							height='2.80637'
							transform='matrix(0.719844 0.694136 -0.719844 0.694136 150.595 1.38741)'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<rect
							y='0.347068'
							width='0.602124'
							height='2.80637'
							transform='matrix(0.719844 0.694136 -0.719844 0.694136 144.645 7.12502)'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<rect
							x='-0.359922'
							width='0.602124'
							height='2.80637'
							transform='matrix(-0.719844 0.694136 -0.719844 -0.694136 150.52 9.56362)'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<rect
							x='-0.359922'
							width='0.602124'
							height='2.80637'
							transform='matrix(-0.719844 0.694136 -0.719844 -0.694136 144.569 3.82601)'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.5'
						/>
						<rect
							x='43.8422'
							y='18.6797'
							width='0.707259'
							height='3.13439'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.575'
						/>
						<path
							d='M41.023 24.5529V25.2144H37.7513V24.5529H41.023Z'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.575'
						/>
						<path
							d='M48.5024 20.2555L48.9952 20.7306L46.6891 22.9544L46.1963 22.4793L48.5024 20.2555Z'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.575'
						/>
						<path
							d='M41.7021 26.8128L42.1949 27.288L39.8888 29.5118L39.396 29.0366L41.7021 26.8128Z'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.575'
						/>
						<path
							d='M42.195 22.4793L41.7022 22.9545L39.396 20.7307L39.8888 20.2555L42.195 22.4793Z'
							fill='#D0E4F0'
							stroke='#D0E4F0'
							strokeWidth='0.575'
						/>
						<rect
							x='6.32959'
							y='105.486'
							width='1.12198'
							height='4.32762'
							fill='#D0E4F0'
							stroke='#D0E4F0'
						/>
						<rect
							x='6.32959'
							y='112.518'
							width='1.12198'
							height='4.32762'
							fill='#D0E4F0'
							stroke='#D0E4F0'
						/>
						<rect
							x='12.7803'
							y='110.625'
							width='1.08191'
							height='4.48791'
							transform='rotate(90 12.7803 110.625)'
							fill='#D0E4F0'
							stroke='#D0E4F0'
						/>
						<rect
							x='5.48779'
							y='110.625'
							width='1.08191'
							height='4.48791'
							transform='rotate(90 5.48779 110.625)'
							fill='#D0E4F0'
							stroke='#D0E4F0'
						/>
						<rect
							x='234.651'
							y='119.551'
							width='1.12198'
							height='2.70477'
							fill='#D0E4F0'
							stroke='#D0E4F0'
						/>
						<rect
							x='234.651'
							y='128.206'
							width='1.12198'
							height='2.70477'
							fill='#D0E4F0'
							stroke='#D0E4F0'
						/>
						<rect
							x='241.102'
							y='124.69'
							width='1.08191'
							height='2.80494'
							transform='rotate(90 241.102 124.69)'
							fill='#D0E4F0'
							stroke='#D0E4F0'
						/>
						<rect
							x='232.127'
							y='124.69'
							width='1.08191'
							height='2.80494'
							transform='rotate(90 232.127 124.69)'
							fill='#D0E4F0'
							stroke='#D0E4F0'
						/>
						<defs>
							<linearGradient
								id='paint0_linear_1600_706'
								x1='-94.3831'
								y1='42.3491'
								x2='-38.0532'
								y2='209.413'
								gradientUnits='userSpaceOnUse'
							>
								<stop stopColor='#F4F8FB' />
								<stop offset='1' stopColor='#E6EAF6' />
								<stop offset='1' stopColor='#E6EEF6' />
							</linearGradient>
							<linearGradient
								id='paint1_linear_1600_706'
								x1='-8.09225'
								y1='85.5854'
								x2='29.5723'
								y2='150.866'
								gradientUnits='userSpaceOnUse'
							>
								<stop stopColor='#FEFFFF' />
								<stop offset='1' stopColor='#F7F9FE' />
							</linearGradient>
							<linearGradient
								id='paint2_linear_1600_706'
								x1='44.5197'
								y1='26.7302'
								x2='68.6779'
								y2='76.4802'
								gradientUnits='userSpaceOnUse'
							>
								<stop stopColor='#FEFFFF' />
								<stop offset='1' stopColor='#F7F9FE' />
							</linearGradient>
							<linearGradient
								id='paint3_linear_1600_706'
								x1='87.172'
								y1='74.5328'
								x2='132.515'
								y2='140.048'
								gradientUnits='userSpaceOnUse'
							>
								<stop stopColor='#FEFFFF' />
								<stop offset='1' stopColor='#F7F9FE' />
							</linearGradient>
						</defs>
					</svg>
				)}

				<p className='text-[17px] -mt-4 font-semibold text-center text-primary-700/50'>
					{message}
				</p>
			</div>
		</Card>
	);
};

export default EmptyResourceIndicator;
