import moment from 'moment';

export const getPageName = (pathname) => {
	let pageName;
	const paths = pathname.split('/');

	if (paths.includes('balances')) return 'Outstanding Balances';

	if (paths.includes('all')) return 'All Recent Transactions';

	if (paths.includes('transactions'))
		return (pageName = `${paths[3].slice(0, paths[3].length - 1)} ${paths[2]}`);

	return (pageName = pathname.split('/')[2]);
};

export const getHeaderColor = (pathname) => {
	if (pathname?.includes('all')) return 'text-gold-700';
	if (pathname?.includes('credits')) return 'text-green-700';
	if (pathname?.includes('debits')) return 'text-orange-700';
	if (pathname?.includes('balance')) return 'text-blue-700';
	return 'text-gray-500';
};

export const dispatchCustomEvent = (eventName) => {
	const event = new Event(eventName);
	document.dispatchEvent(event);
};

export function convertDate(
	date,
	options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	}
) {
	const formattedDate = moment(date).format('MMM Do YYYY, hh:mm a');

	return formattedDate;
}

export function makeId(length) {
	var result = '';
	var characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}
