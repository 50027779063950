import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({ className, link }) => {
	return (
		<Link className='inline-block' to={link}>
			<img
				src={'/img/logistics-logo.png'}
				alt='First Exceptional Logo'
				className={`w-7 h-7 ${className}`}
			/>
		</Link>
	);
};

Logo.defaultProps = {
	link: '/',
};

export default Logo;
