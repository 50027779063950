import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './styles/index.css';
import './styles/global.scss';
import App from './App';
import CustomerState from 'context/customers/CustomerState';
import TransactionState from 'context/transactions/TransactionState';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<TransactionState>
				<CustomerState>
					<App />
				</CustomerState>
			</TransactionState>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
