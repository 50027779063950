/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { HiCheck, HiSelector } from 'react-icons/hi';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function ListBox(props) {
	const { menu, label, selected, setSelected, placeholder } = props;

	return (
		<Listbox value={selected} onChange={setSelected}>
			{({ open }) => (
				<>
					{label && (
						<Listbox.Label className='block text-sm font-medium text-gray-700'>
							{label}
						</Listbox.Label>
					)}

					<div className='relative mt-1'>
						<Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-base text-left bg-white rounded-lg shadow-sm cursor-default ring-1 text-primary-700 focus:outline-none focus:ring-primary-500 sm:text-sm ring-opacity-20 focus:ring-opacity-60 focus:ring-2 ring-primary-700'>
							{selected ? (
								<span className='flex items-center'>
									{selected.image && (
										<img
											src={selected.image}
											className='flex-shrink-0 w-6 h-6 mr-3 bg-gray-400 rounded-full animate-pulse'
										/>
									)}
									<span className='block capitalize truncate'>
										{selected.name}
									</span>
								</span>
							) : (
								<span className='text-gray-600/60'>
									{placeholder ? placeholder : 'No option selected'}
								</span>
							)}
							<span className='absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none'>
								<HiSelector
									className='w-5 h-5 text-gray-400'
									aria-hidden='true'
								/>
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave='transition ease-in duration-100'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<Listbox.Options className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
								{menu?.map((item) => (
									<Listbox.Option
										key={item.id}
										className={({ active }) =>
											classNames(
												active ? 'text-white bg-primary-600' : 'text-gray-900',
												'cursor-default select-none relative py-2 pl-3 pr-9'
											)
										}
										value={item}
									>
										{({ selected, active }) => (
											<>
												<div className='flex items-center'>
													{item.image && (
														<img
															src={item.image}
															className='flex-shrink-0 w-6 h-6 mr-3 bg-gray-400 rounded-full animate-pulse'
														/>
													)}
													<span
														className={classNames(
															selected ? 'font-semibold' : 'font-normal',
															'block truncate capitalize'
														)}
													>
														{item.name}
													</span>
												</div>

												{selected ? (
													<span
														className={classNames(
															active ? 'text-white' : 'text-primary-600',
															'absolute inset-y-0 right-0 flex items-center pr-4'
														)}
													>
														<HiCheck className='w-5 h-5' aria-hidden='true' />
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
}
