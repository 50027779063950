import React from 'react';

const Card = ({ children, className }) => {
	return (
		<div
			className={`bg-white overflow-hidden rounded-lg border card-shadow ${className}`}
		>
			{children}
		</div>
	);
};

export default Card;
