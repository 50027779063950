import { useForm } from 'react-hook-form';
import { useAuth, changePassword } from 'context/auth/AuthState';

const PasswordForm = () => {
	const [authState, authDispatch] = useAuth();
	const { authError } = authState;

	const {
		handleSubmit,
		register,
		setError,
		formState: { errors },
	} = useForm();

	const onSubmit = (values) => {
		const { confirm_password, password } = values;

		if (confirm_password !== password) {
			setError('password', { message: 'Passwords do not match' });
		} else {
			delete values.confirm_password;
			changePassword(authDispatch, values);
		}
	};

	return (
		<div className='flex items-center justify-center w-full'>
			<div className='w-full max-w-md px-8 py-12 pt-10 space-y-8 bg-white rounded-xl'>
				<div className='flex flex-col items-center'>
					<h2 className='mt-6 text-2xl font-bold text-primary-500'>
						Change Password
					</h2>
				</div>

				<form className='mt-8 min-w-[400px]' onSubmit={handleSubmit(onSubmit)}>
					<div className='-space-y-px rounded-md shadow-sm'>
						<div>
							<label htmlFor='password' className='sr-only'>
								Email
							</label>
							<input
								id='password'
								name='password'
								type='password'
								autoComplete='email'
								{...register('password', {
									required: {
										value: true,
										message: 'Please enter your password',
									},
								})}
								className={`relative block w-full px-3 py-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-gold-500 focus:border-gold-500 focus:z-10 sm:text-base 
                  ${
										errors.email || authError?.email ? '!border-red-400' : null
									}`}
								placeholder='New Password'
							/>
						</div>

						<div>
							<label htmlFor='confirm_password' className='sr-only'>
								Confirm Password
							</label>
							<input
								id='confirm_password'
								name='confirm_password'
								type='password'
								{...register('confirm_password', {
									required: {
										value: true,
										message: 'Enter your password again.',
									},
								})}
								required
								className={`relative block w-full px-3 py-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-gold-500 focus:border-gold-500 focus:z-10 sm:text-base`}
								placeholder='Confirm Password'
							/>
						</div>
					</div>

					<div>
						<span className='mr-1 text-red-400'>
							{errors?.password?.message}
						</span>
						<span className='text-red-400'>{authError?.password}</span>
					</div>

					<div className='mt-12'>
						<button
							type='submit'
							className='relative flex items-center justify-center w-full px-4 py-4 text-lg font-bold border border-transparent rounded-full text-primary-500 bg-gold-500 group hover:bg-gold-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500'
						>
							Change Password
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PasswordForm;
