import React, { useReducer } from 'react';
import axios from 'axios';

import TransactionContext from './transactionContext';
import transactionReducer from './transactionReducer';
import { transactions } from './../types';
import exceptionalAPI from 'context/exceptionalAPI';
import { toast } from 'react-toastify';
import { dispatchCustomEvent } from 'helpers/';

const recentTransactions = {
	data: [
		{
			id: 1,
			ref: 'exp-dk21k74',
			created_at: '2021-10-30',
			user: {
				id: 1,
				created_at: '2021-10-10',
				mobile: '0241244468',
				name: 'Francis Agyapong',
				email: 'fagyapong@gmailcom',
				location: 'Kumasi Aboabo',
				img: 'https://randomuser.me/api/portraits/men/60.jpg',
			},
			credits: 2008,
			debits: 432,
			balance: 1576,
		},
		{
			id: 2,
			ref: 'exp-dk21k21',
			created_at: '2021-11-11',
			user: {
				id: 2,
				created_at: '2019-03-14',
				mobile: '0242244469',
				name: 'Diana Hamilton',
				email: 'eagyapong@gmailcom',
				location: 'Pankrono Atimatim',
				img: 'https://randomuser.me/api/portraits/women/64.jpg',
			},
			credits: 1008,
			debits: 432,
			balance: 576,
		},
		{
			id: 3,
			ref: 'exp-dk21k32',
			created_at: '2021-12-18',
			user: {
				id: 3,
				created_at: '2000-03-18',
				mobile: '0241246468',
				name: 'Eric Agyano Manso',
				email: 'agyano@gmailcom',
				location: 'Tantra Hill',
				img: 'https://randomuser.me/api/portraits/men/63.jpg',
			},
			credits: 2000,
			debits: 432,
			balance: 1568,
		},
		{
			id: 4,
			ref: 'exp-dk21k67',
			created_at: '2022-02-20',
			user: {
				id: 4,
				created_at: '2020-03-18',
				mobile: '0202244468',
				name: 'Mavis Hlorwu',
				email: 'mavis@gmailcom',
				location: 'Ablekuma',
				img: 'https://randomuser.me/api/portraits/women/5.jpg',
			},
			credits: 2008,
			debits: 432,
			balance: 1576,
		},
		{
			id: 5,
			ref: 'exp-dk21kj0',
			created_at: '2022-03-11',
			user: {
				id: 5,
				created_at: '2020-03-18',
				mobile: '0277074805',
				name: 'Thomas Osei',
				email: 'tosei@gmailcom',
				location: 'Abetifi',
				img: 'https://randomuser.me/api/portraits/men/32.jpg',
			},
			credits: 11308,
			debits: 7622,
			balance: 3686,
		},
		{
			id: 6,
			ref: 'exp-dk21kj0',
			created_at: '2022-03-11',
			user: {
				id: 5,
				created_at: '2020-03-18',
				mobile: '0277074805',
				name: 'Thomas Osei',
				email: 'tosei@gmailcom',
				location: 'Abetifi',
				img: 'https://randomuser.me/api/portraits/men/32.jpg',
			},
			credits: 11308,
			debits: 7622,
			balance: 3686,
		},
		{
			id: 7,
			ref: 'exp-dk21k74',
			created_at: '2021-10-30',
			user: {
				id: 1,
				created_at: '2021-10-10',
				mobile: '0241244468',
				name: 'Francis Agyapong',
				email: 'fagyapong@gmailcom',
				location: 'Kumasi Aboabo',
				img: 'https://randomuser.me/api/portraits/men/60.jpg',
			},
			credits: 2008,
			debits: 432,
			balance: 1576,
		},
		{
			id: 8,
			ref: 'exp-dk21k74',
			created_at: '2021-11-11',
			user: {
				id: 2,
				created_at: '2019-03-14',
				mobile: '0242244469',
				name: 'Diana Hamilton',
				email: 'eagyapong@gmailcom',
				location: 'Pankrono Atimatim',
				img: 'https://randomuser.me/api/portraits/women/64.jpg',
			},
			credits: 1008,
			debits: 432,
			balance: 576,
		},
		{
			id: 9,
			ref: 'exp-dk21k32',
			created_at: '2021-12-18',
			user: {
				id: 3,
				created_at: '2000-03-18',
				mobile: '0241246468',
				name: 'Eric Agyano Manso',
				email: 'agyano@gmailcom',
				location: 'Tantra Hill',
				img: 'https://randomuser.me/api/portraits/men/63.jpg',
			},
			credits: 2000,
			debits: 432,
			balance: 1568,
		},
		{
			id: 10,
			ref: 'exp-dk21k74',
			created_at: '2021-10-30',
			user: {
				id: 1,
				created_at: '2021-10-10',
				mobile: '0241244468',
				name: 'Francis Agyapong',
				email: 'fagyapong@gmailcom',
				location: 'Kumasi Aboabo',
				img: 'https://randomuser.me/api/portraits/men/60.jpg',
			},
			credits: 2008,
			debits: 432,
			balance: 1576,
		},
	],
};

const TransactionState = (props) => {
	// Create initial state
	const initialState = {
		transactions: null,
		transactionStatistics: {
			total_credit: 0,
			total_debit: 0,
			balance: 0,
		},
		transactionSummary: null,
		debits: null,
		credits: null,
		currentTransaction: null,
		filteredTransaction: null,
		transactionsError: null,
		customerTransactions: null,
		transactionsLoading: true,
	};

	// Init Reducer
	const [state, dispatch] = useReducer(transactionReducer, initialState);

	//===============
	// Actions
	//===============

	// Get transactions
	const getTransactions = async (customerId) => {
		try {
			const { data } = await exceptionalAPI.get(
				customerId ? `/transaction/customer/${customerId}` : '/transaction/all'
			);
			dispatch({
				type: transactions.FETCH_TRANSACTIONS,
				payload: data,
			});
		} catch (err) {
			dispatch({ type: transactions.TRANSACTION_ERROR });
			toast.error('Sorry something went wrong. Could not fetch transactions.');
		}
	};

	// Get transaction summary
	const getTransactionSummary = async () => {
		try {
			const { data } = await exceptionalAPI.get(
				'transaction/customers/summary'
			);
			dispatch({
				type: transactions.FETCH_TRANSACTIONS_SUMMARY,
				payload: data,
			});
		} catch (err) {
			dispatch({ type: transactions.TRANSACTION_ERROR });
			toast.error('Sorry something went wrong. Could not fetch transactions.');
		}
	};

	const getTransactionStatistics = async (customerId) => {
		try {
			const { data } = await exceptionalAPI.get(
				customerId
					? `/transaction/dashboard/summary/${customerId}`
					: `/transaction/dashboard/summary`
			);
			dispatch({
				type: transactions.TRANSACTION_STATS,
				payload: data,
			});
		} catch (err) {
			dispatch({ type: transactions.TRANSACTION_ERROR });
			toast.error('Could not fetch transaction statistics.');
		}
	};

	// Get customer transactions
	const getCustomerTransactions = async (id) => {
		try {
			const { data } = await exceptionalAPI.get(`/transaction/customer/${id}`);
			dispatch({
				type: transactions.FETCH_CUSTOMER_TRANSACTIONS,
				payload: data,
			});
		} catch (err) {
			dispatch({ type: transactions.TRANSACTION_ERROR });
			toast.error('Could not fetch customer transactions.');
		}
	};

	// Add transaction
	const createTransaction = async (transaction) => {
		const id = toast.loading('Creating customer');

		try {
			const res = await exceptionalAPI.post(
				'/transaction/create',
				transaction,
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			dispatch({
				type: transactions.ADD_TRANSACTION,
				payload: res.data,
			});

			getTransactions();

			toast.update(id, {
				render: 'Transaction created successfully.',
				type: 'success',
				isLoading: false,
				autoClose: 3000,
			});

			dispatchCustomEvent('closeModal');
		} catch (err) {
			dispatch({ type: transactions.TRANSACTION_ERROR });
			toast.update(id, {
				render: err.message ?? 'Sorry, we could not create transaction.',
				type: 'error',
				isLoading: false,
				autoClose: 3000,
			});
		}
	};

	// Delete transaction
	const deleteTransaction = async (id) => {
		try {
			const res = await axios.delete(`api/transactions/${id}`);

			dispatch({
				type: transactions.DELETE_TRANSACTION,
				payload: id,
			});

			getTransactions();
		} catch (err) {
			dispatch({
				type: transactions.TRANSACTION_ERROR,
				payload: err.response.msg,
			});
		}
	};

	// Set current transaction
	const setCurrent = (transaction) => {
		dispatch({
			type: transactions.SET_CURRENT_TRANSACTION,
			payload: transaction,
		});
	};

	// Clear transactions
	const clearTransactions = () => {
		dispatch({
			type: transactions.CLEAR_TRANSACTIONS,
		});
	};

	// Clear current customer transactions
	const clearCurrentCustomerTransactions = () => {
		dispatch({
			type: transactions.CLEAR_CURRENT_CUSTOMER_TRANSACTIONS,
		});
	};

	// Clear current transaction
	const clearCurrent = () => {
		dispatch({
			type: transactions.CLEAR_CURRENT_TRANSACTION,
		});
	};

	// Update transaction
	const updateTransaction = async (transaction) => {
		const config = {
			'Content-Type': 'application/json',
		};

		try {
			const res = await axios.put(
				`api/transactions/${transaction._id}`,
				transaction,
				config
			);

			dispatch({
				type: transactions.UPDATE_TRANSACTION,
				payload: res.data,
			});

			getTransactions();
		} catch (err) {
			dispatch({
				type: transactions.TRANSACTION_ERROR,
				payload: err.response.msg,
			});
		}
	};

	// Filter transactions
	const filterTransactions = (text) => {
		dispatch({
			type: transactions.FILTER_TRANSACTIONS,
			payload: text,
		});
	};

	// Clear filter
	const clearFilter = () => {
		dispatch({
			type: transactions.CLEAR_FILTERED_TRANSACTIONS,
		});
	};

	return (
		<TransactionContext.Provider
			value={{
				transactions: state.transactions,
				debits: state.debits,
				credits: state.credits,
				transactionSummary: state.transactionSummary,
				transactionStatistics: state.transactionStatistics,
				currentTransaction: state.currentTransaction,
				filteredTransaction: state.filteredTransaction,
				transactionsError: state.transactionsError,
				customerTransactions: state.customerTransactions,
				transactionsLoading: state.transactionsLoading,
				getTransactions,
				getTransactionSummary,
				getTransactionStatistics,
				getCustomerTransactions,
				createTransaction,
				updateTransaction,
				deleteTransaction,
				setCurrent,
				clearCurrent,
				filterTransactions,
				clearFilter,
				clearTransactions,
				clearCurrentCustomerTransactions,
			}}
		>
			{props.children}
		</TransactionContext.Provider>
	);
};

export default TransactionState;
