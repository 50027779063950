import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../ui/Button';
import ListBox from '../ui/ListBox';
import CustomerContext from '../../context/customers/customerContext';
import TransactionContext from '../../context/transactions/transactionContext';

const CustomerTransactionForm = ({ transaction }) => {
	const [checked, setChecked] = useState(false);

	const customerContext = useContext(CustomerContext);
	const { customers, customerLoading, getCustomers } = customerContext;

	const transactionContext = useContext(TransactionContext);
	const { createTransaction, transactionsLoading } = transactionContext;

	const dropDownCustomer = customers?.data.map((customer) => ({
		id: customer.id,
		name: `${customer?.first_name} ${customer?.last_name}`,
		image:
			customer?.profileImage ||
			'https://via.placeholder.com/150/C8D4E2/4b4b4b?text=N/A',
	}));

	const [selectedCustomer, setSelectedCustomer] = useState(dropDownCustomer[0]);

	const {
		handleSubmit,
		register,
		formState: { errors },
		setValue,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: { amount: transaction?.balance },
	});

	useEffect(() => {
		getCustomers();
	}, []);

	useEffect(() => {
		setValue('user_id', `${selectedCustomer.id}`);
	}, [selectedCustomer]);

	const onSubmit = (formValues) => {
		if (!selectedCustomer) {
			setError('customer', {
				message: 'Please select a customer',
			});
			return setTimeout(() => clearErrors(), 3000);
		}

		formValues.trans_status = checked ? 'debit' : 'credit';

		createTransaction(formValues);
	};

	return (
		<div>
			<form
				className='flex justify-start px-4 py-2 text-left form'
				onSubmit={handleSubmit(onSubmit)}
				noValidate
			>
				<div className='flex flex-col w-full h-full form__wrapper'>
					<h1 className='mb-6 text-xl font-semibold text-opacity-80 text-primary-700'>
						Record Transaction
					</h1>

					{/* Switch */}
					<div className=''>
						<label
							htmlFor='toggler'
							className='block text-sm font-medium leading-7 capitalize text-primary-500/80'
						>
							Select Transaction Type
						</label>

						<div className='flex items-center justify-between w-full px-6 py-3 border rounded-lg border-primary-500/40 focus:border-2'>
							<p
								className={`font-semibold text-primary-500/80 ${
									!checked && 'text-green-500'
								}`}
							>
								Credit
							</p>
							<label htmlFor='toggler' className='relative block'>
								{/* <!-- input --> */}
								<input
									type='checkbox'
									id='toggler'
									className='sr-only'
									checked={checked}
									onChange={(e) => setChecked(e.target.checked)}
								/>
								{/* <!-- line --> */}
								<div className='block h-8 bg-gray-600 rounded-full w-14'></div>
								{/* <!-- dot --> */}
								<div
									className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
										checked
											? 'translate-x-[100%] bg-orange-500'
											: 'bg-green-500'
									}`}
								></div>
							</label>
							<p
								className={`font-semibold text-primary-500/80 ${
									checked && 'text-orange-500'
								}`}
							>
								Debit
							</p>
						</div>
					</div>

					{/* Dropdown */}
					<div className='form-group'>
						{customers?.data.length > 0 && (
							<ListBox
								menu={dropDownCustomer}
								label={'Select Customer'}
								selected={selectedCustomer}
								setSelected={setSelectedCustomer}
							/>
						)}
						<span className='mr-1 text-red-400'>{errors?.customer}</span>
					</div>

					<div className='form-group'>
						<label htmlFor='price'>
							Amount
							<span className='text-xs text-gray-500'>($)</span>
						</label>

						<input
							autoComplete='off'
							placeholder='Enter Amount'
							type='number'
							// max={selectedBooking?.price}
							min={0}
							{...register('amount', {
								required: {
									value: true,
									message: 'Transaction Amount is required.',
								},
							})}
							className={`${errors.amount ? 'ring-2 ring-red-400' : null}`}
						/>

						<span className='mr-1 text-red-400'>{errors?.amount?.message}</span>
					</div>

					<div className='flex justify-end mt-6'>
						<Button
							type='submit'
							className='text-base text-gray-100 capitalize'
							bgClassName={`${'bg-primary-500'}`}
						>
							Record Transaction
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default CustomerTransactionForm;
