import React, { useReducer } from 'react';
import { toast } from 'react-toastify';
import CustomerContext from './customerContext';
import customerReducer from './customerReducer';
import { customers } from './../types';
import exceptionalAPI from '../exceptionalAPI';
import { dispatchCustomEvent } from 'helpers/';
import axios from 'axios';

const CustomerState = (props) => {
	// Create initial state
	const initialState = {
		customers: null,
		currentCustomer: null,
		filteredCustomer: null,
		customersError: null,
		customersLoading: true,
	};

	// Init Reducer
	const [state, dispatch] = useReducer(customerReducer, initialState);

	//==========
	// Actions
	//==========

	// Get customers
	const getCustomers = async () => {
		try {
			const { data } = await exceptionalAPI.get(`/customer/all`);
			dispatch({
				type: customers.GET_CUSTOMERS,
				payload: data,
			});
		} catch (err) {
			dispatch({ type: customers.CUSTOMER_ERROR });
			toast.error('Sorry something went wrong. Could not fetch customers.');
		}
	};

	// Get single customer
	const getCustomer = async (id) => {
		try {
			const { data } = await exceptionalAPI.get(`/customer/${id}`);
			dispatch({
				type: customers.GET_CUSTOMER,
				payload: data.data,
			});
		} catch (err) {
			dispatch({ type: customers.CUSTOMER_ERROR });
			toast.error(
				'Sorry something went wrong. Could not fetch customer detail.'
			);
		}
	};

	// Add customer
	const createCustomer = async (customer) => {
		try {
			const { data } = await exceptionalAPI.post('/customer/create', customer);

			dispatch({
				type: customers.ADD_CUSTOMER,
				payload: data,
			});

			getCustomers();

			dispatchCustomEvent('closeModal');
			toast.success('Customers created successfully');
		} catch (err) {
			dispatch({ type: customers.CUSTOMER_ERROR });
			toast.error('Sorry something went wrong. Could not create customers.');
		}
	};

	// Delete customer
	const deleteCustomer = async (id) => {
		try {
			const { data } = await exceptionalAPI.delete(`/customer/${id}`);

			dispatch({
				type: customers.DELETE_CUSTOMER,
				payload: data,
			});

			getCustomers();
		} catch (err) {
			dispatch({ type: customers.CUSTOMER_ERROR });
			toast.error('Sorry we could not delete customer.');
		}
	};

	// Set current customer
	const setCurrent = (customer) => {
		dispatch({
			type: customers.SET_CURRENT,
			payload: customer,
		});
	};

	// Clear customers
	const clearCustomers = () => {
		dispatch({
			type: customers.CLEAR_CUSTOMERS,
		});
	};

	// Clear current customer
	const clearCurrent = () => {
		dispatch({
			type: customers.CLEAR_CURRENT,
		});
	};

	const uploadToCloudinary = async (image) => {
		const formData = new FormData();

		formData.append('file', image);
		formData.append('upload_preset', '1st_exceptional_upload');

		const { data } = await axios.post(
			'https://api.cloudinary.com/v1_1/owatech/image/upload',
			formData
		);

		return data;
	};

	// Update customer
	const updateCustomer = async (formValues, customerId) => {
		let uploadLoaderId = toast.loading('Updating customer');

		try {
			let updatedFormValues;
			const { image, cardType } = formValues || {};

			if (image) {
				const acceptedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
				const acceptedCapacity = 5000000;

				// Check file type
				if (!acceptedTypes.includes(image?.type))
					return toast.update(uploadLoaderId, {
						render: 'Select file type is not supported.',
						type: 'error',
						isLoading: false,
						autoClose: 3000,
					});

				// Check file capacity
				if (image?.size >= acceptedCapacity)
					return toast.update(uploadLoaderId, {
						render: 'File capacity cannot be more than 5MB.',
						type: 'error',
						isLoading: false,
						autoClose: 3000,
					});

				const imageRes = await uploadToCloudinary(image);

				updatedFormValues = {
					...formValues,
					[cardType]: imageRes.secure_url,
				};
				delete updatedFormValues.image;
				delete updatedFormValues.cardType;
			} else {
				updatedFormValues = formValues;
			}

			const { data } = await exceptionalAPI.put(
				`/customer/update/${customerId}`,
				updatedFormValues
			);

			dispatch({
				type: customers.UPDATE_CUSTOMER,
				payload: data,
			});

			dispatchCustomEvent('closeSlideOver');

			getCustomers();

			toast.update(uploadLoaderId, {
				render: 'Customer updated successfully',
				type: 'success',
				isLoading: false,
				autoClose: 3000,
			});
		} catch (err) {
			dispatch({ type: customers.CUSTOMER_ERROR });
			toast.update(uploadLoaderId, {
				render: 'Sorry we could not update customer details.',
				type: 'error',
				isLoading: false,
				autoClose: 3000,
			});
		}
	};

	// Filter customers
	const filterCustomers = (text) => {
		dispatch({
			type: customers.FILTER_CUSTOMERS,
			payload: text,
		});
	};

	// Clear filter
	const clearFilter = () => {
		dispatch({
			type: customers.CLEAR_FILTER,
		});
	};

	return (
		<CustomerContext.Provider
			value={{
				customers: state.customers,
				currentCustomer: state.currentCustomer,
				filteredCustomer: state.filteredCustomer,
				customersError: state.customersError,
				customersLoading: state.customersLoading,
				getCustomers,
				getCustomer,
				createCustomer,
				updateCustomer,
				deleteCustomer,
				setCurrent,
				clearCurrent,
				filterCustomers,
				clearFilter,
				clearCustomers,
				uploadToCloudinary,
			}}
		>
			{props.children}
		</CustomerContext.Provider>
	);
};

export default CustomerState;
