import React from 'react';
import { useForm } from 'react-hook-form';

import Button from '../ui/Button';

const EditSmsForm = ({ currentCustomer }) => {
	const {
		handleSubmit,
		register,
		formState: { errors },
		setError,
	} = useForm();

	const onSubmit = (formValues) => {
		console.log(formValues);
	};

	return (
		<div>
			<form
				className='flex justify-start px-4 py-2 text-left form'
				onSubmit={handleSubmit(onSubmit)}
				noValidate
			>
				<div className='flex flex-col w-full h-full form__wrapper'>
					<h1 className='text-xl font-semibold text-opacity-80 text-primary-700'>
						{currentCustomer ? 'Send Message to User' : 'Send Bulk Message'}
					</h1>

					<p className='py-2 text-primary-700 text-opacity-60'>
						{currentCustomer
							? 'Send message to single user'
							: 'Send message to all users'}
					</p>

					<div className='form-group'>
						<label htmlFor='title'>Title</label>
						<input
							autoComplete='off'
							id='title'
							placeholder='Enter Message title'
							type='text'
							{...register('title', {
								required: {
									value: true,
									message: 'You must enter a title.',
								},
							})}
							className={`${errors.title ? 'ring-2 ring-red-400' : null}`}
						/>
						{errors?.title && (
							<span className='mr-1 text-red-400'>
								{errors?.title?.message}
							</span>
						)}
					</div>

					<div className='form-group'>
						<label htmlFor='description'>Description</label>
						<textarea
							autoComplete='off'
							id='description'
							placeholder='Add message content'
							{...register('description', {
								required: {
									value: true,
									message: 'You must add content.',
								},
							})}
							className={`resize-y !min-h-[250px] !max-h-[400px] ${
								errors.description ? 'ring-2 ring-red-400' : null
							}`}
						></textarea>
						<span className='mr-1 text-red-400'>
							{errors?.description?.message}
						</span>
					</div>

					<div className='flex justify-end mt-3'>
						<Button
							type='submit'
							className='text-base text-gray-100 capitalize'
							bgClassName={`${'bg-primary-500'}`}
						>
							Send Message
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default EditSmsForm;
