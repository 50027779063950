import { Link } from 'react-router-dom';

const AuthDescription = () => {
	return (
		<div
			className='relative w-full h-full overflow-hidden border-r-4 border-gold-500'
			style={{
				background: `url(/img/gold-bg.jpg) no-repeat center center/cover`,
			}}
		>
			{/* // Chat Bubble */}
			<div className='m-10 chat-message'>
				<div className='flex items-end'>
					<div className='flex flex-col items-start order-2 max-w-xs mx-2 space-y-2 text-base'>
						<div>
							<span className='inline-block px-4 py-2 text-gray-600 bg-gray-300 rounded-lg rounded-bl-none'>
								Welcome to 1st Exceptional Logistics.
							</span>
						</div>
					</div>
					<img
						src='/img/logo.png'
						alt='My profile'
						className='order-1 rounded-full w-7 h-7'
					/>
				</div>
			</div>

			{/* <div className='relative flex items-center justify-center w-full h-full text-center'>
				<div className='absolute flex items-center justify-center bottom-10'>
					<ul className='flex gap-4'>
						<li className='text-base font-semibold text-primary-700'>
							<Link to='/auth/about'>About</Link>
						</li>
						<li className='text-base font-semibold text-primary-700'>
							<Link to='/auth/terms-of-use'>Terms Of Use</Link>
						</li>
					</ul>
				</div>
			</div> */}
		</div>
	);
};

export default AuthDescription;
