const auth = {
	REGISTER_SUCCESS: 'REGISTER_SUCCESS',
	REGISTER_FAIL: 'REGISTER_FAIL',
	USER_LOADED: 'USER_LOADED',
	AUTH_ERROR: 'AUTH_ERROR',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAIL: 'LOGIN_FAIL',
	CLEAR_ERRORS: 'CLEAR_ERRORS',
	LOGOUT: 'LOGOUT',
};

const customers = {
	CREATE_CUSTOMER: 'CREATE_CUSTOMER',
	FETCH_CUSTOMERS: 'FETCH_CUSTOMERS',
	FETCH_CUSTOMERS_ERROR: 'FETCH_CUSTOMERS_ERROR',
	GET_CUSTOMERS: 'GET_CUSTOMERS',
	GET_CUSTOMER: 'GET_CUSTOMER',
	ADD_CUSTOMER: 'ADD_CUSTOMER',
	DELETE_CUSTOMER: 'DELETE_CUSTOMER',
	SET_CURRENT: 'SET_CURRENT',
	CLEAR_CURRENT: 'CLEAR_CURRENT',
	UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
	CUSTOMER_ERROR: 'CUSTOMER_ERROR',
	FILTER_CUSTOMERS: 'FILTER_CUSTOMERS',
	CLEAR_CUSTOMERS: 'CLEAR_CUSTOMERS',
	CLEAR_FILTERED_CUSTOMERS: 'CLEAR_FILTERED_CUSTOMERS',
	CLEAR_CUSTOMER_ERRORS: 'CLEAR_CUSTOMER_ERRORS',
};

const alerts = {
	SET_ALERT: 'SET_ALERT',
	REMOVE_ALERT: 'REMOVE_ALERT',
};

const transactions = {
	TRANSACTION_STATS: 'TRANSACTION_STATS',
	FETCH_TRANSACTIONS: 'FETCH_TRANSACTIONS',
	FETCH_TRANSACTIONS_SUMMARY: 'FETCH_TRANSACTIONS_SUMMARY',
	FETCH_CUSTOMER_TRANSACTIONS: 'FETCH_CUSTOMER_TRANSACTIONS',
	FETCH_TRANSACTIONS_ERROR: 'FETCH_TRANSACTIONS_ERROR',
	FETCH_CUSTOMER_TRANSACTIONS: 'FETCH_CUSTOMER_TRANSACTIONS',
	FETCH_CUSTOMER_TRANSACTIONS_ERROR: 'FETCH_CUSTOMER_TRANSACTIONS_ERROR',
	DEBIT_ACCOUNT: 'DEBIT_ACCOUNT',
	DEBIT_ACCOUNT_ERROR: 'DEBIT_ACCOUNT_ERROR',
	CREDIT_ACCOUNT: 'CREDIT_ACCOUNT',
	CREDIT_ACCOUNT_ERROR: 'CREDIT_ACCOUNT_ERROR',
	SET_CURRENT_TRANSACTION: 'SET_CURRENT_TRANSACTION',
	CLEAR_CURRENT_TRANSACTION: 'CLEAR_CURRENT_TRANSACTION',
	CLEAR_CURRENT_CUSTOMER_TRANSACTIONS: 'CLEAR_CURRENT_CUSTOMER_TRANSACTIONS',
	FILTER_TRANSACTIONS: 'FILTER_TRANSACTIONS',
	CLEAR_TRANSACTIONS: 'CLEAR_TRANSACTIONS',
	CLEAR_FILTERED_TRANSACTIONS: 'CLEAR_FILTERED_TRANSACTIONS',
	TRANSACTION_ERROR: 'TRANSACTION_ERROR',
	DELETE_TRANSACTION: 'DELETE_TRANSACTION',
	UPDATE_TRANSACTION: 'UPDATE_TRANSACTION',
	ADD_TRANSACTION: 'ADD_TRANSACTION',
};

Object.freeze([customers, transactions, alerts, auth]);

export { customers, transactions, alerts, auth };
