import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'context/auth/AuthState';
import Spinner from 'components/ui/Spinner';

const PrivateRoute = ({ component: Component }) => {
	const [authState] = useAuth();
	const { isAuthenticated, authLoading } = authState;

	if (authLoading) return <Spinner />;
	if (isAuthenticated) return <Component />;
	return <Navigate to='/' />;
};

export default PrivateRoute;
