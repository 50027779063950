import { customers } from './../types';

export default (state, { type, payload }) => {
	switch (type) {
		case customers.GET_CUSTOMERS:
			return {
				...state,
				customers: payload,
				customersLoading: false,
			};

		case customers.GET_CUSTOMER:
			return {
				...state,
				currentCustomer: payload,
				customersLoading: false,
			};

		case customers.ADD_CUSTOMER:
			return {
				...state,
				customers: {
					data: [payload.data, ...state.customers.data],
				},
				customersLoading: false,
			};

		case customers.UPDATE_CUSTOMER:
			return {
				...state,
				customers: {
					data: state.customers.data.map((customer) =>
						customer._id === payload._id ? payload : customer
					),
				},
				customersLoading: false,
			};

		case customers.DELETE_CUSTOMER:
			return {
				...state,
				customers: state.customers.data.filter(
					(customer) => customer._id !== payload
				),
				customersLoading: false,
			};

		case customers.SET_CURRENT:
			return {
				...state,
				currentCustomer: payload,
			};

		case customers.CLEAR_CURRENT:
			return {
				...state,
				currentCustomer: null,
			};

		case customers.FILTER_CUSTOMERS:
			return {
				...state,
				filteredCustomers: state.customers.data.filter((customer) => {
					const regex = RegExp(`${payload}`, 'gi');
					return customer.name.match(regex) || customer.email.match(regex);
				}),
				customersLoading: false,
			};

		case customers.CLEAR_CUSTOMERS:
			return {
				...state,
				customers: null,
				filteredCustomers: null,
				customersError: null,
				currentCustomer: null,
				customersLoading: false,
			};

		case customers.CLEAR_FILTER:
			return {
				...state,
				filteredCustomers: null,
			};

		case customers.CUSTOMER_ERROR:
			return {
				...state,
				customersLoading: false,
			};

		default:
			break;
	}
};
