import { useContext } from 'react';
import { IoPencilSharp } from 'react-icons/io5';
import PropTypes from 'prop-types';

import CustomerContext from 'context/customers/customerContext';

const IdCardHolder = ({ image, cardType, containerClassName }) => {
	const customerContext = useContext(CustomerContext);
	const { updateCustomer, currentCustomer } = customerContext;

	const handleFileUpload = (e) => {
		updateCustomer(
			{
				image: e.target.files[0],
				cardType,
				...currentCustomer,
			},
			currentCustomer?.id
		);
	};

	return (
		<div
			className={`relative h-[180px] w-full overflow-hidden rounded-lg ${containerClassName}`}
		>
			{cardType === 'profilePic' && !image ? (
				<div className='flex items-center justify-center w-full h-full text-4xl font-bold text-white bg-primary-500'>
					{currentCustomer?.first_name?.charAt(0) +
						currentCustomer?.last_name?.charAt(0)}
				</div>
			) : (
				<img
					src={image}
					className={`object-cover rounded-lg border w-full h-full ${
						!image && 'animate-pulse bg-gray-400'
					}`}
				/>
			)}

			{!image && cardType !== 'profilePic' && (
				<div className='absolute inset-0 z-20 flex items-center justify-center p-2'>
					<span className='text-lg font-semibold text-gray-800'>
						Upload {cardType === 'passport' ? 'Passport' : 'National ID'}
					</span>
				</div>
			)}

			{/* Bg Overlay */}
			{image && cardType !== 'profilePic' && (
				<p className='absolute font-semibold inset-x-0 bottom-0 h-[50px] flex items-center px-6 bg-gray-900/30 text-white'>
					{cardType === 'passport' ? 'Passport' : 'National ID'}
				</p>
			)}

			{/* Edit Button */}
			<div className='absolute inset-0 z-30 flex items-end justify-end p-2'>
				<label
					htmlFor='national_id'
					className='relative flex items-center justify-center overflow-hidden font-bold text-gray-700 duration-300 border-gray-300 rounded-full w-9 h-9 group hover:bg-gold-500 bg-white/90'
				>
					<IoPencilSharp className='w-5 h-5 duration-300 group-hover:text-white' />
					<input
						type='file'
						id='national_id'
						className='absolute inset-0 z-20 w-full h-full opacity-0'
						onChange={handleFileUpload}
					/>
				</label>
			</div>
		</div>
	);
};

IdCardHolder.prototype = {
	cardType: PropTypes.string.isRequired,
};

export default IdCardHolder;
