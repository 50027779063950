import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import { useAuth, login } from 'context/auth/AuthState';

const LoginForm = () => {
	const [authState, authDispatch] = useAuth();
	const { authError, isAuthenticated } = authState;

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm();

	const onLogin = (values) => {
		values.email = values.email.toLowerCase();
		login(authDispatch, values);
	};

	if (isAuthenticated) return <Navigate to='/app/dashboard' />;

	return (
		<div>
			<div className='w-full max-w-md px-8 py-12 pt-10 space-y-8 rounded-xl'>
				<div className='flex flex-col items-center'>
					<img
						className='w-16 h-16 -mr-4'
						src='/img/logistics-logo.png'
						alt='1st exceptional mining'
					/>
					<h2 className='mt-6 text-2xl font-bold text-primary-500'>
						Metal Account Login
					</h2>
				</div>

				<form className='mt-8 min-w-[400px]' onSubmit={handleSubmit(onLogin)}>
					<div className='-space-y-px rounded-md shadow-sm'>
						<div>
							<label htmlFor='email-address' className='sr-only'>
								Email
							</label>
							<input
								id='email-address'
								name='email'
								type='email'
								autoComplete='email'
								{...register('email', {
									required: {
										value: true,
										message: 'Please enter your email',
									},
									pattern: {
										value:
											/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: 'Please enter a valid email',
									},
								})}
								className={`relative block w-full px-3 py-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-gold-500 focus:border-gold-500 focus:z-10 sm:text-base 
                  ${
										errors.email || authError?.email ? '!border-red-400' : null
									}`}
								placeholder='Email address'
							/>
						</div>

						<div>
							<label htmlFor='password' className='sr-only'>
								Password
							</label>
							<input
								id='password'
								name='password'
								type='password'
								{...register('password', {
									required: {
										value: true,
										message: 'Please enter your password',
									},
								})}
								required
								className={`relative block w-full px-3 py-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-gold-500 focus:border-gold-500 focus:z-10 sm:text-base`}
								placeholder='Password'
							/>
						</div>
					</div>

					<div>
						<span className='mr-1 text-red-400'>
							{errors?.email?.message || errors?.password?.message}
						</span>
						<span className='text-red-400'>
							{authError?.email || authError?.password}
						</span>
					</div>

					<div className='flex items-center justify-end mt-6'>
						<div>
							<a
								href='#'
								className='font-medium text-gold-700 hover:text-primary-500'
							>
								Forgot your password?
							</a>
						</div>
					</div>

					<div className='mt-12'>
						<button
							type='submit'
							className='relative flex duration-500 items-center justify-center w-full px-4 py-4 text-lg font-bold border border-transparent rounded-full text-primary-500 bg-gold-500 group hover:bg-gold-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold-500'
						>
							Login
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default LoginForm;
