import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	ArrowNarrowRightIcon,
	SwitchVerticalIcon,
	TrendingDownIcon,
	TrendingUpIcon,
} from '@heroicons/react/outline';
import TransactionContext from 'context/transactions/transactionContext';
import { useAuth } from 'context/auth/AuthState';

const Statistics = () => {
	const transactionContext = useContext(TransactionContext);
	const {
		transactionStatistics,
		transactions,
		filteredTransactions,
		getTransactionStatistics,
	} = transactionContext;
	const [authState] = useAuth();
	const { user } = authState;

	useEffect(() => {
		if (!user) return;

		getTransactionStatistics(user.is_admin == 0 ? user.id : null);
		// eslint-disable-next-line
	}, [transactions]);

	return (
		<div>
			{/* Cards */}
			<div className='grid gap-2 mb-8 sm:gap-6 md:grid-cols-3'>
				{/* Item */}
				<div className='min-w-0 mt-4 overflow-hidden bg-white border-2 rounded-lg shadow-lg border-primary-500/20'>
					<div className='flex items-start px-4 py-5'>
						<div className='p-3 mr-4 text-green-500 bg-green-100 rounded-lg'>
							<TrendingUpIcon className='w-6 h-6' />
						</div>
						<div>
							<p className='mb-2 text-lg font-semibold text-gray-600 capitalize'>
								Credit Transactions
							</p>
							<p className='text-3xl font-bold text-primary-500'>
								{transactionStatistics.total_credit}
							</p>
						</div>
					</div>

					<Link
						to='/app/transactions/credits'
						className='flex items-center justify-between px-4 py-3 mt-1 text-sm font-semibold text-green-600 uppercase cursor-pointer bg-primary-500/5'
					>
						<span>See All</span>
						<ArrowNarrowRightIcon className='w-7 h-7' />
					</Link>
				</div>

				{/* Item */}
				<div className='min-w-0 mt-4 overflow-hidden bg-white border-2 rounded-lg shadow-lg border-primary-500/20'>
					<div className='flex items-start px-4 py-5'>
						<div className='p-3 mr-4 text-orange-500 bg-orange-100 rounded-lg'>
							<TrendingDownIcon className='w-6 h-6' />
						</div>
						<div>
							<p className='mb-2 text-lg font-semibold text-gray-600 capitalize'>
								Debit Transactions
							</p>
							<p className='text-3xl font-bold text-primary-500'>
								{transactionStatistics.total_debit}
							</p>
						</div>
					</div>

					<Link
						to='/app/transactions/debits'
						className='flex items-center justify-between px-4 py-3 mt-1 text-sm font-semibold text-orange-600 uppercase cursor-pointer bg-primary-500/5'
					>
						<span>See All</span>
						<ArrowNarrowRightIcon className='w-7 h-7' />
					</Link>
				</div>

				{/* Item */}
				<div className='min-w-0 mt-4 overflow-hidden bg-white border-2 rounded-lg shadow-lg border-primary-500/20'>
					<div className='flex items-start px-4 py-5'>
						<div className='p-3 mr-4 text-blue-500 bg-blue-100 rounded-lg'>
							<SwitchVerticalIcon className='w-6 h-6' />
						</div>
						<div>
							<p className='mb-2 text-lg font-semibold text-gray-600 capitalize'>
								Outstanding Balance
							</p>
							<p className='text-3xl font-bold text-primary-500'>
								{transactionStatistics.balance}
							</p>
						</div>
					</div>

					{user?.is_admin != 0 && (
						<Link
							to='/app/transactions/balances'
							className='flex items-center justify-between px-4 py-3 mt-1 text-sm font-semibold text-blue-600 uppercase cursor-pointer bg-primary-500/5'
						>
							<span>See All</span>
							<ArrowNarrowRightIcon className='w-7 h-7' />
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export default Statistics;
