import { useContext, useEffect, useState } from 'react';

import Table from '../components/Tables/Default';
import Card from '../components/ui/Card';
import CustomerContext from '../context/customers/customerContext';
import CustomerDetail from '../components/customers/CustomerDetail';
import { convertDate } from '../helpers';
import SlideOver from '../components/ui/SlideOver';
import Modal from '../components/ui/Modal';
import EditSmsForm from '../components/customers/EditSmsForm';
import TableSkeletonLoader from 'components/ui/TableSkeletonLoader';
import EmptyResourceIndicator from 'components/ui/EmptyResourceIndicator';

const Customers = () => {
	const [isDetailSlideOverOpen, setDetailSlideOverOpen] = useState(false);
	const [currentCustomer, setCurrentCustomer] = useState(false);
	const [isEditCustomerModalOpen, setEditCustomerModalOpen] = useState(false);
	const [isSMSModalOpen, setSMSModalOpen] = useState(false);

	const customerContext = useContext(CustomerContext);
	const { customers, filteredCustomers, getCustomers, customerLoading } =
		customerContext;

	useEffect(() => {
		getCustomers();
		// eslint-disable-next-line
	}, []);

	const buildTableBody = (data) =>
		data.map((item) => {
			const {
				first_name,
				last_name,
				profileImage,
				email,
				location,
				mobileNumber,
				joinedOn,
				refNo,
				id,
				nationalCard,
				passport,
			} = item;

			return {
				ref: refNo,
				imageText: {
					text: `${first_name} ${last_name}`,
					image: profileImage,
				},
				email,
				mobile: mobileNumber,
				location,
				joined: convertDate(joinedOn),
			};
		});

	const onCloseDetailSlideOver = () => {
		// dispatch(clearBookingFormValues());
	};

	const onRecordClicked = (record) => {
		const customerId = customers.data.filter(
			(customer) => customer.email == record.email
		);

		record.id = customerId[0].id;

		setCurrentCustomer(record);
		setDetailSlideOverOpen(true);
	};

	const onMessageCustomer = () => {
		setDetailSlideOverOpen(false);
		setSMSModalOpen(true);
	};

	return (
		<div>
			<Card className='flex flex-col h-full'>
				<div className='flex items-center justify-between w-full p-4 py-4'>
					<h1 className='text-xl font-semibold text-opacity-80 text-gold-700'>
						Customers
					</h1>
				</div>

				{customerLoading && !customers ? (
					<TableSkeletonLoader
						fields={[
							{ type: 'text', text: 'Ref #' },
							{ type: 'image', text: 'Name' },
							{ type: 'text', text: 'Email' },
							{ type: 'text', text: 'Mobile' },
							{ type: 'text', text: 'Location' },
							{ type: 'text', text: 'Joined' },
						]}
					/>
				) : customers?.data?.length > 0 ? (
					<Table
						headings={[
							{ text: 'Ref #' },
							{
								text: 'Image',
								type: 'imageText',
								style: 'hover:underline hover:cursor-pointer',
							},
							{
								text: 'Email',
								style: 'font-normal text-blue-600 ',
							},
							{ text: 'Mobile' },
							{ text: 'Location' },
							{ text: 'Joined' },
							// { type: 'action', text: ' ' },
						]}
						onRecordClicked={onRecordClicked}
						body={buildTableBody(customers.data)}
					/>
				) : (
					<EmptyResourceIndicator message={'No customer found'} />
				)}
			</Card>

			<SlideOver
				containerWidth={'lg:max-w-xl xl:max-w-2xl'}
				open={isDetailSlideOverOpen}
				setOpen={setDetailSlideOverOpen}
				scrollable
			>
				<CustomerDetail
					setDetailSlideOverOpen={setDetailSlideOverOpen}
					customer={currentCustomer}
					onMessageCustomer={onMessageCustomer}
				/>
			</SlideOver>

			<Modal
				isModalOpen={isSMSModalOpen}
				setModalOpen={setSMSModalOpen}
				onCloseModal={() => {}}
				containerStyle='min-w-[500px] w-[500px] min-h-[400px] pt-5 bg-white rounded-lg py-8 px-3'
				closeButtonStyle='text-2xl font-bold text-gray-700'
				closeOnOutsideClick={false}
				modalPosition='center'
			>
				<EditSmsForm currentCustomer={currentCustomer} />
			</Modal>
		</div>
	);
};

export default Customers;
