/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { HiX } from 'react-icons/hi';

export default function SlideOver({
	open,
	isOpen,
	setOpen,
	title,
	containerWidth,
	scrollable,
	children,
}) {
	useEffect(() => {
		const closeModalEvent = document.addEventListener('closeSlideOver', () =>
			setOpen(false)
		);

		return () => {
			document.removeEventListener('closeSlideOver', closeModalEvent);
		};
	}, []);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as='div'
				className='fixed inset-0 z-[1000] overflow-hidden'
				onClose={setOpen}
			>
				<div className='absolute inset-0 overflow-hidden'>
					<Transition.Child
						as={Fragment}
						enter='ease-in-out duration-500'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in-out duration-500'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<Dialog.Overlay className='absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
					</Transition.Child>
					<div className='fixed inset-y-0 right-0 flex max-w-full pl-10'>
						<Transition.Child
							as={Fragment}
							enter='transform transition ease-in-out duration-250 sm:duration-700'
							enterFrom='translate-x-full'
							enterTo='translate-x-0'
							leave='transform transition ease-in-out duration-250 sm:duration-700'
							leaveFrom='translate-x-0'
							leaveTo='translate-x-full'
						>
							<div
								className={`relative w-screen ${
									containerWidth ? containerWidth : 'max-w-md'
								}`}
							>
								<Transition.Child
									as={Fragment}
									enter='ease-in-out duration-500'
									enterFrom='opacity-0'
									enterTo='opacity-100'
									leave='ease-in-out duration-500'
									leaveFrom='opacity-100'
									leaveTo='opacity-0'
								>
									<div className='absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4'>
										<button
											type='button'
											className='text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
											onClick={() => setOpen(false)}
										>
											<span className='sr-only'>Close panel</span>
											<HiX className='w-6 h-6' aria-hidden='true' />
										</button>
									</div>
								</Transition.Child>

								{/* // * Main Content */}
								<div
									className={`flex flex-col h-full py-6 bg-white shadow-xl overflow-x-hidden ${
										scrollable ? 'overflow-y-scroll' : 'overflow-hidden'
									}`}
								>
									{title && (
										<div className='px-4 sm:px-6'>
											<Dialog.Title
												as='h1'
												className={
													title?.className
														? title.className
														: 'pb-6 text-xl font-semibold capitalize border-b text-opacity-80 text-primary-700'
												}
											>
												{title.text}
											</Dialog.Title>
										</div>
									)}

									<div className='relative flex flex-1'>{children}</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
