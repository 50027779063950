import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';

import Statistics from '../components/dashboard/Statistics';
import Table from '../components/Tables/Default';
import Card from '../components/ui/Card';
import { convertDate } from '../helpers';
import TransactionContext from '../context/transactions/transactionContext';
import TableSkeletonLoader from 'components/ui/TableSkeletonLoader';
import EmptyResourceIndicator from 'components/ui/EmptyResourceIndicator';
import { useAuth } from 'context/auth/AuthState';

export default function Dashboard() {
	const transactionContext = useContext(TransactionContext);
	const [authState, authDispatch] = useAuth();
	const { user } = authState;

	const {
		transactions,
		filteredTransactions,
		getTransactions,
		transactionsLoading,
	} = transactionContext;

	useEffect(() => {
		getTransactions(user?.is_admin == 0 ? user?.id : null);

		// eslint-disable-next-line
	}, []);

	const buildTableBody = (transactionList) => {
		return transactionList.map((item) => {
			const {
				user: { first_name, last_name, email, refNo, image },
				amount,
				trans_status,
				created_at,
			} = item;

			return {
				refNo,
				imageText: {
					text: `${first_name} ${last_name}`,
					image: image,
				},
				email,
				debit: `${trans_status == 'DEBIT' ? `$${amount}` : '-'}`,
				credit: `${trans_status == 'CREDIT' ? `$${amount}` : '-'}`,
				date: convertDate(created_at),
			};
		});
	};

	return (
		<div>
			<Statistics />

			<Card className='flex flex-col h-full'>
				<div className='flex items-center justify-between w-full p-4 py-4 text-primary-500'>
					<h1 className='text-xl font-semibold text-opacity-80'>
						Recent Transactions
					</h1>

					<Link
						to='/app/transactions/all'
						className='flex items-center gap-2 mt-1 text-sm font-semibold uppercase cursor-pointer'
					>
						<span>See All</span>
						<ArrowNarrowRightIcon className='w-7 h-7' />
					</Link>
				</div>

				{transactionsLoading && !transactions ? (
					<TableSkeletonLoader
						fields={[
							{ type: 'text', text: 'Ref #' },
							{ type: 'image', text: 'Customer' },
							{ type: 'text', text: 'email' },
							{ type: 'text', text: 'Debit' },
							{ type: 'text', text: 'Credit' },
							{ type: 'text', text: 'Date' },
						]}
					/>
				) : transactions?.data?.length > 0 ? (
					<Table
						headings={[
							{ text: 'Ref #' },
							{
								text: 'Image',
								type: 'imageText',
							},
							{
								text: 'Email',
								style: 'font-normal hover:underline',
							},
							{ text: 'Debit', style: 'text-orange-700' },
							{ text: 'Credit', style: 'text-green-700' },
							{ text: 'Date' },
						]}
						body={buildTableBody(transactions.data.slice(0, 10))}
					/>
				) : (
					<EmptyResourceIndicator message={'No transaction found'} />
				)}
			</Card>
		</div>
	);
}
