import { auth } from '../types';

export default (state, { type, payload }) => {
	switch (type) {
		case auth.USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				authLoading: false,
				user: payload.data[0],
			};

		case auth.REGISTER_SUCCESS:
		case auth.LOGIN_SUCCESS:
			return {
				...state,
				token: payload.access_token.data.token,
				user: payload.user,
				isAuthenticated: true,
				authLoading: false,
			};

		case auth.REGISTER_FAIL:
		case auth.AUTH_ERROR:
		case auth.LOGIN_FAIL:
		case auth.LOGOUT:
			return {
				...state,
				...payload,
				isAuthenticated: false,
				authLoading: false,
				user: null,
				token: null,
				authError: payload,
			};

		case auth.CLEAR_ERRORS:
			return {
				...state,
				authError: null,
			};

		default:
			return state;
	}
};
