import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useContext, useEffect } from 'react';

import DashboardNavigation from '../dashboard/DashboardNavigation';
import Header from './Header';
import CustomerContext from 'context/customers/customerContext';

const AppLayout = () => {
	const customerContext = useContext(CustomerContext);
	const { getCustomers } = customerContext;

	useEffect(() => {
		getCustomers();
	}, []);

	const { pathname } = useLocation();

	return (
		<div className='min-h-full'>
			<DashboardNavigation />

			<Header />

			{pathname === '/app' && <Navigate to='/app/dashboard' />}

			<main className='px-2 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8'>
				<Outlet />
			</main>
		</div>
	);
};

export default AppLayout;
