import exceptionalAPI from 'context/exceptionalAPI';

const setAuthToken = (token) => {
	if (token) {
		exceptionalAPI.defaults.headers.common['Authorization'] = token;
		localStorage.setItem('token', token);
	} else {
		delete exceptionalAPI.defaults.headers.common['Authorization'];
		localStorage.removeItem('token');
	}

	// exceptionalAPI.defaults.headers.common['Content-Type'] = `application/json`;
};

export default setAuthToken;
