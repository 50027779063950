import React, { cloneElement } from 'react';
import { MdOutlineMessage } from 'react-icons/md';
import PropTypes from 'prop-types';

// * NOTE: Expected data format is set as default prop at the bottom of the page

const Table = (props) => {
	const { pagination, headings, body, onRecordClicked } = props;

	const renderHeadings = (cols) => (
		<tr>
			{cols?.length > 0 &&
				cols.map((col, index) => {
					return (
						<th
							key={index}
							index={`th-${index}`}
							scope='col'
							className='px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase'
						>
							{col.text}
						</th>
					);
				})}
		</tr>
	);

	const renderBody = (rows) =>
		rows?.length > 0 &&
		rows.map((row, index) => {
			return (
				<tr
					key={index}
					index={`tr-${index}`}
					scope='row'
					onClick={() => onRecordClicked(row)}
					className='w-full px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500'
				>
					{renderCells(row)}
				</tr>
			);
		});

	const renderCells = (cells) =>
		Object.values(cells).map((cell, index) => {
			// Profile
			if (headings[index].type === 'imageText')
				return (
					<td key={index} className='px-6 py-2 whitespace-nowrap'>
						<div className='flex items-center'>
							<div className='flex-shrink-0 w-10 h-10 mr-2 sm:mr-3'>
								<img
									src={
										cell.image ||
										'https://via.placeholder.com/150/C8D4E2/4b4b4b?text=N/A'
									}
									width='40'
									height='40'
									className='min-h-[40px] min-w-[40px] object-cover rounded-full w-10 h-10'
									alt={cell.text || 'N/A'}
								/>
							</div>
							<div
								className={`text-base font-medium tracking-wider text-left text-gray-500 ${headings[index]?.style}`}
							>
								{cell.text || 'N/A'}
							</div>
						</div>
					</td>
				);

			// Images
			if (headings[index].type === 'image')
				return (
					<td key={index} className={`px-6 py-2`}>
						<img
							className={`inline-block animate-pulse bg-gray-400 rounded-full xl:w-10 xl:h-10 w-9 h-9 ${headings[index]?.style}`}
							src={
								cell || 'https://via.placeholder.com/150/C8D4E2/4b4b4b?text=N/A'
							}
						/>
					</td>
				);

			// Statuses
			if (headings[index].type == 'status')
				return (
					<td key={index} className='px-6 py-3 capitalize whitespace-nowrap'>
						<span
							className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full min-w-[55px] justify-center bg-green-100 text-green-800 ${headings[index]?.style}`}
						>
							{cell}
						</span>
					</td>
				);

			// if (headings[index].type == 'action') {
			// 	return (
			// 		<td key={index} className='px-6 py-3 capitalize whitespace-nowrap'>
			// 			<button>
			// 				{cell.text}
			// 				<cell.icon className='flex-shrink-0 w-6 h-6 text-black' />
			// 			</button>
			// 		</td>
			// 	);
			// }

			// Normal Text
			return (
				<td
					key={index}
					index={`td-${index}`}
					scope='cell'
					className='px-6 py-2.5 text-base font-medium tracking-wider text-left text-gray-500'
				>
					<div className={`${headings[index]?.style}`}>{cell || 'N/A'}</div>
				</td>
			);
		});

	return (
		<div className='table w-full'>
			<div className='flex flex-col'>
				<div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
					<div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
						<div className='overflow-x-hidden border-b border-gray-200'>
							<table className='min-w-full divide-y divide-gray-200'>
								<thead className='bg-[#EDF2F9]'>
									{renderHeadings(headings)}
								</thead>

								{body && (
									<tbody className='bg-white divide-y divide-gray-200'>
										{renderBody(body)}
									</tbody>
								)}
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Table.propTypes = {
	headings: PropTypes.array.isRequired,
	body: PropTypes.array.isRequired,
};

Table.defaultProps = {
	headings: [
		{ text: 'Photo', type: 'image' },
		{ text: 'Name', type: 'text' },
		{ text: 'Email', type: 'text', style: 'text-blue-700 underline' },
	],
	body: [
		{
			photo: 'https://randomuser.me/api/portraits/men/60.jpg',
			name: 'Eric Oppong',
			email: 'eric@gmail.com',
		},
		{
			photo: 'https://randomuser.me/api/portraits/women/60.jpg',
			name: 'Maame Serwaa',
			email: 'maame@gmail.com',
		},
		{
			photo: 'https://randomuser.me/api/portraits/women/69.jpg',
			name: 'Gyenimaah Phoebe',
			email: 'gyenimaah@gmail.com',
		},
	],
};

export default Table;
