import { RefreshIcon } from '@heroicons/react/outline';
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { MdOutlineMessage } from 'react-icons/md';
import { FiUserPlus } from 'react-icons/fi';

import { getPageName, getHeaderColor } from '../../helpers';
import Button from '../ui/Button';
import Modal from '../ui/Modal';
import CustomerTransactionForm from '../customers/CustomerTransactionForm';
import CustomersContext from 'context/customers/customerContext';
import EditCustomerForm from '../customers/EditCustomerForm';
import EditSmsForm from '../customers/EditSmsForm';
import { useAuth } from 'context/auth/AuthState';

const Header = () => {
	const [isTransactionModalOpen, setTransactionModalOpen] = useState(false);
	const [isEditCustomerModalOpen, setEditCustomerModalOpen] = useState(false);
	const [isSMSModalOpen, setSMSModalOpen] = useState(false);
	const [currentPageName, setCurrentPageName] = useState();
	const [headerColor, setHeaderColor] = useState();
	const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);

	const { customersLoading } = useContext(CustomersContext);

	const [authState, authDispatch] = useAuth();
	const { user, authLoading } = authState;

	let location = useLocation();
	const userName = user ? `${user?.first_name}` : '';

	useEffect(() => {
		const pageName = getPageName(location.pathname);

		if (pageName === 'dashboard') {
			setCurrentPageName(`Welcome ${userName}`);
		} else {
			setCurrentPageName(pageName);
		}

		setHeaderColor(getHeaderColor(location.pathname));
	}, [location]);

	const onCloseTransactionModal = () => {
		// dispatch(clearBookingFormValues());
	};

	const onCloseCustomerModal = () => {};

	return (
		<header className='bg-white shadow'>
			<div className='flex items-center justify-between px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8'>
				{/* ${headerColor} */}
				<h1 className={`text-2xl font-semibold capitalize text-primary-500`}>
					{currentPageName}
				</h1>

				<div className='flex items-center gap-2'>
					{user?.is_admin != 0 && (
						<Button
							type='button'
							textContent='Transact'
							className={`hover:bg-gold-700/80  bg-gold-700 text-primary-500 duration-500`}
							bgClassName={`bg-none`}
							onClick={() => setTransactionModalOpen(true)}
							disabled={authLoading || customersLoading}
						>
							<RefreshIcon className='w-6 h-6 text-xl font-bold' />
						</Button>
					)}

					{location?.pathname.includes('customers') && (
						<>
							<Button
								type='button'
								textContent='Create Customer'
								className={`hover:bg-blue-700/80  bg-blue-700 text-white duration-500`}
								bgClassName={`bg-none`}
								onClick={() => setEditCustomerModalOpen(true)}
							>
								<FiUserPlus className='w-6 h-6 text-xl font-bold' />
							</Button>
							<Button
								type='button'
								textContent='Bulk Message'
								className={`hover:bg-primary-700/80  bg-primary-700 text-white duration-500`}
								bgClassName={`bg-none`}
								onClick={() => setSMSModalOpen(true)}
							>
								<MdOutlineMessage className='w-6 h-6 text-xl font-bold' />
							</Button>
						</>
					)}
				</div>
			</div>

			<Modal
				isModalOpen={isTransactionModalOpen}
				setModalOpen={setTransactionModalOpen}
				onCloseModal={onCloseTransactionModal}
				containerStyle='min-w-[500px] w-[500px] min-h-[400px] pt-5 bg-white rounded-lg py-8 px-3'
				closeButtonStyle='text-2xl font-bold text-gray-700'
				closeOnOutsideClick={false}
				modalPosition='center'
			>
				<CustomerTransactionForm />
			</Modal>

			<Modal
				isModalOpen={isEditCustomerModalOpen}
				setModalOpen={setEditCustomerModalOpen}
				onCloseModal={onCloseCustomerModal}
				containerStyle='min-w-[500px] w-[500px] min-h-[400px] pt-5 bg-white rounded-lg py-8 px-3'
				closeButtonStyle='text-2xl font-bold text-gray-700'
				closeOnOutsideClick={false}
				modalPosition='center'
			>
				<EditCustomerForm />
			</Modal>

			<Modal
				isModalOpen={isSMSModalOpen}
				setModalOpen={setSMSModalOpen}
				onCloseModal={() => {}}
				containerStyle='min-w-[500px] w-[500px] min-h-[400px] pt-5 bg-white rounded-lg py-8 px-3'
				closeButtonStyle='text-2xl font-bold text-gray-700'
				closeOnOutsideClick={false}
				modalPosition='center'
			>
				<EditSmsForm />
			</Modal>
		</header>
	);
};

export default Header;
