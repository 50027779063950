import { Link, useLocation } from 'react-router-dom';

import { useAuth } from 'context/auth/AuthState';

const TransactionsNavigator = () => {
	const [authState, authDispatch] = useAuth();
	const { user } = authState;

	const { pathname } = useLocation();

	return (
		<>
			<div
				className={`flex w-full min-h-[42px] max-w-2xl overflow-hidden font-normal border border-primary-500 text-primary-500 divide-x divide-primary-500 ${
					pathname.split('/').length === 2 ? 'rounded-t-md' : 'rounded-md'
				}`}
			>
				<Link
					to='/app/transactions/all'
					className={`flex items-center justify-center flex-1 px-2 lg:px-4 hover:bg-primary-500 hover:text-gray-100 ${[
						pathname.includes('all')
							? 'bg-primary-500 text-white'
							: 'bg-transparent',
					]}`}
				>
					Recent
				</Link>

				<Link
					to='/app/transactions/credits'
					className={`flex items-center justify-center flex-1 px-2 lg:px-4 hover:bg-primary-500 hover:text-gray-100 ${[
						pathname.includes('credits')
							? 'bg-primary-500 text-white'
							: 'bg-transparent',
					]}`}
				>
					Credits
				</Link>

				<Link
					to='/app/transactions/debits'
					className={`flex items-center justify-center flex-1 px-2 lg:px-4 hover:bg-primary-500 hover:text-gray-100 ${[
						pathname.includes('debits')
							? 'bg-primary-500 text-white'
							: 'bg-transparent',
					]}`}
				>
					Debits
				</Link>

				{user?.is_admin != 0 && (
					<Link
						to='/app/transactions/balances'
						className={`flex items-center justify-center flex-1 px-2 lg:px-4 hover:bg-primary-500 hover:text-gray-100 ${[
							pathname.includes('balances')
								? 'bg-primary-500 text-white'
								: 'bg-transparent',
						]}`}
					>
						Balances
					</Link>
				)}
			</div>
		</>
	);
};

export default TransactionsNavigator;
