import React, { useContext, useEffect, useState } from 'react';

import {
	SwitchVerticalIcon,
	TrendingDownIcon,
	TrendingUpIcon,
} from '@heroicons/react/outline';
import { MdOutlineMessage } from 'react-icons/md';
import TransactionsContext from 'context/transactions/transactionContext';
import CustomersContext from 'context/customers/customerContext';
import { convertDate } from 'helpers';
import Table from '../Tables/Default';
import Button from '../ui/Button';
import TableSkeletonLoader from 'components/ui/TableSkeletonLoader';
import EmptyResourceIndicator from 'components/ui/EmptyResourceIndicator';
import _ from 'lodash';
import IdCardHolder from './IdCardHolder';

const CustomerDetail = ({ customer, onMessageCustomer }) => {
	const [totalCredit, setTotalCredit] = useState(0);
	const [totalDebit, setTotalDebit] = useState(0);

	const {
		getCustomerTransactions,
		customerTransactions,
		transactionsLoading,
		clearCurrentCustomerTransactions,
	} = useContext(TransactionsContext);
	const { getCustomer, customersLoading, currentCustomer } =
		useContext(CustomersContext);

	useEffect(() => {
		getCustomer(customer.id);
		getCustomerTransactions(customer.id);
	}, []);

	const { ref, imageText, email, joined, location, mobile } = customer;

	useEffect(() => {
		const myCredits = customerTransactions?.data
			?.filter((t) => t.trans_status == 'CREDIT')
			?.map((t) => +t.amount)
			?.reduce((sum, current) => sum + current, 0);

		const myDebits = customerTransactions?.data
			?.filter((t) => t.trans_status == 'DEBIT')
			?.map((t) => +t.amount)
			?.reduce((sum, current) => sum + current, 0);

		_.isFinite(myCredits) && setTotalCredit(myCredits);
		_.isFinite(myDebits) && setTotalDebit(myDebits);
	}, [customerTransactions]);

	const buildTableBody = (data) =>
		data.map((item) => {
			const { amount, trans_status, created_at } = item;

			return {
				date: convertDate(created_at),
				debit: `${trans_status == 'DEBIT' ? `$${amount}` : '-'}`,
				credit: `${trans_status == 'CREDIT' ? `$${amount}` : '-'}`,
			};
		});

	return (
		<div className='w-full'>
			<div className='w-full overflow-hidden bg-white'>
				<div className='flex items-start justify-between pb-5'>
					<div className='w-full px-4 py-5 pt-0 sm:px-6'>
						<h3 className='text-xl font-semibold leading-6 text-primary-500'>
							Customer Information
						</h3>
						<p className='max-w-2xl mt-1 mb-3 text-gray-500'>
							Personal details and transaction history.
						</p>

						<Button
							type='button'
							textContent='Send Message'
							className={`hover:!bg-primary-700/80 !bg-transparent border-primary-700/80 border-2 text-primary-600 hover:text-white duration-500`}
							onClick={onMessageCustomer}
						>
							<MdOutlineMessage className='w-6 h-6 text-xl font-bold' />
						</Button>
					</div>

					{imageText && (
						<div className='flex flex-col items-end mr-6'>
							<IdCardHolder
								image={currentCustomer ? imageText.image : null}
								cardType='profilePic'
								containerClassName='flex-shrink-0 !w-28 !h-28'
							/>
							{!currentCustomer?.profileImage && (
								<p className='w-full text-primary-600'>Upload Image</p>
							)}
						</div>
					)}
				</div>

				<div className='w-full border-t border-gray-200'>
					<dl className='w-full'>
						{ref && (
							<div className='w-full px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
								<dt className='text-sm font-semibold text-gray-500'>
									Reference Number
								</dt>
								<dd className='mt-1 text-primary-700 sm:mt-0 sm:col-span-2'>
									{ref}
								</dd>
							</div>
						)}

						{imageText && (
							<div className='w-full px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
								<dt className='text-sm font-semibold text-gray-500'>
									Full name
								</dt>
								<dd className='mt-1 text-primary-700 sm:mt-0 sm:col-span-2'>
									{imageText.text}
								</dd>
							</div>
						)}

						{email && (
							<div className='w-full px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
								<dt className='text-sm font-semibold text-gray-500'>
									Email address
								</dt>
								<dd className='mt-1 text-blue-600 sm:mt-0 sm:col-span-2'>
									{email}
								</dd>
							</div>
						)}

						{mobile && (
							<div className='w-full px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
								<dt className='text-sm font-semibold text-gray-500'>
									Phone Number
								</dt>
								<dd className='mt-1 text-primary-700 sm:mt-0 sm:col-span-2'>
									{mobile}
								</dd>
							</div>
						)}

						{location && (
							<div className='w-full px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
								<dt className='text-sm font-semibold text-gray-500'>
									Location
								</dt>
								<dd className='mt-1 text-primary-700 sm:mt-0 sm:col-span-2'>
									{location}
								</dd>
							</div>
						)}

						{joined && (
							<div className='w-full px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
								<dt className='text-sm font-semibold text-gray-500'>
									Joined At
								</dt>
								<dd className='mt-1 text-primary-700 sm:mt-0 sm:col-span-2'>
									{joined}
								</dd>
							</div>
						)}

						<div className='grid w-full grid-cols-2 gap-4 px-6 mb-6'>
							{customersLoading && !currentCustomer ? (
								Array.from(Array(2).keys).map((n, i) => (
									<div
										key={i}
										className='w-full h-full overflow-hidden bg-gray-400 border rounded-lg animate-pulse'
									></div>
								))
							) : currentCustomer ? (
								<>
									<IdCardHolder
										image={currentCustomer?.nationalCard}
										cardType='nationalCard'
									/>
									<IdCardHolder
										image={currentCustomer?.passport}
										cardType='passport'
									/>
								</>
							) : (
								<EmptyResourceIndicator
									removeIcon
									message={'Could not load customer ID'}
								/>
							)}
						</div>

						{/* Transaction Stats */}
						{customerTransactions && (
							<div className='w-full px-4 py-5 bg-white border-t sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
								<dt className='text-sm font-semibold text-gray-500 sm:mt-0 sm:col-span-3'>
									Transactions Statistics
								</dt>

								<dd className='mt-1 text-sm text-primary-700 sm:mt-0 sm:col-span-3'>
									<ul
										role='list'
										className='border border-gray-200 divide-y divide-gray-200 rounded-md'
									>
										<li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
											<div className='flex items-center flex-1 w-0'>
												<TrendingUpIcon
													className='flex-shrink-0 w-5 h-5 text-green-500'
													aria-hidden='true'
												/>
												<span className='flex-1 w-0 ml-2 truncate'>
													Total Credits
												</span>
											</div>
											<div className='flex-shrink-0 ml-4'>
												<a
													href='#'
													className='font-medium text-indigo-600 hover:text-indigo-500'
												>
													$
													<span className='text-base font-semibold text-primary-500'>
														{totalCredit.toFixed(2)}
													</span>
												</a>
											</div>
										</li>
										<li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
											<div className='flex items-center flex-1 w-0'>
												<TrendingDownIcon
													className='flex-shrink-0 w-5 h-5 text-orange-500'
													aria-hidden='true'
												/>
												<span className='flex-1 w-0 ml-2 truncate'>
													Total Credits
												</span>
											</div>
											<div className='flex-shrink-0 ml-4'>
												<a
													href='#'
													className='font-medium text-indigo-600 hover:text-indigo-500'
												>
													$
													<span className='text-base font-semibold text-primary-500'>
														{totalDebit.toFixed(2)}
													</span>
												</a>
											</div>
										</li>
										<li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
											<div className='flex items-center flex-1 w-0'>
												<SwitchVerticalIcon
													className='flex-shrink-0 w-5 h-5 text-blue-500'
													aria-hidden='true'
												/>
												<span className='flex-1 w-0 ml-2 truncate'>
													Balance
												</span>
											</div>
											<div className='flex-shrink-0 ml-4'>
												<a
													href='#'
													className='font-medium text-indigo-600 hover:text-indigo-500'
												>
													$
													<span className='text-base font-semibold text-primary-500'>
														{(totalCredit - totalDebit).toFixed(2)}
													</span>
												</a>
											</div>
										</li>
									</ul>
								</dd>
							</div>
						)}

						{/* Transaction Stats */}
						{customerTransactions && (
							<div className='w-full px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
								<dt className='text-sm font-semibold text-gray-500 sm:mt-0 sm:col-span-3'>
									Transaction History
								</dt>

								<dd className='mt-1 overflow-hidden text-sm border border-gray-200 rounded-md text-primary-700 sm:mt-0 sm:col-span-3'>
									{transactionsLoading && !customerTransactions ? (
										<TableSkeletonLoader
											fields={[
												{ type: 'text', text: 'Date' },
												{ type: 'text', text: 'Debit' },
												{ type: 'text', text: 'Credit' },
											]}
										/>
									) : customerTransactions?.data?.length > 0 ? (
										<Table
											headings={[
												{ text: 'Date' },
												{ text: 'Debit', style: 'text-orange-700' },
												{ text: 'Credit', style: 'text-green-700' },
											]}
											body={buildTableBody(customerTransactions?.data)}
										/>
									) : (
										<EmptyResourceIndicator message={'No transaction found'} />
									)}
								</dd>
							</div>
						)}
					</dl>
				</div>
			</div>
		</div>
	);
};

export default CustomerDetail;
