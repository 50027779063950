import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthDescription from 'components/auth/AuthDescription';
import LoginForm from 'components/auth/LoginForm';
import { useAuth } from 'context/auth/AuthState';

const Home = () => {
	const navigate = useNavigate();

	const [authState, authDispatch] = useAuth();
	const { isAuthenticated } = authState;

	useEffect(() => {
		// Redirect when authenticated
		if (isAuthenticated) {
			navigate('/app/dashboard');
		}

		// eslint-disable-next-line
	}, [isAuthenticated]);

	return (
		<div className='relative lg:grid lg:grid-cols-2 xl:grid-cols-[1fr,700px] w-screen h-screen'>
			<div className='hidden lg:block'>
				<AuthDescription />
			</div>

			<div className='relative z-10 flex items-center justify-center w-full h-full lg:border-l lg:border-primary-500/25  bg-gradient-to-br from-gray-200 to-gold-500/50 '>
				<LoginForm />
			</div>
		</div>
	);
};

export default Home;
