import React from 'react';

const TableSkeletonLoader = ({ fields }) => {
	return (
		<div className='flex flex-col'>
			<div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
				<div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
					<div className='overflow-hidden border-b border-gray-200 shadow'>
						<table className='min-w-full divide-y divide-gray-200'>
							<thead className='bg-[#EDF2F9]'>
								<tr>
									{fields &&
										fields.length > 0 &&
										fields.map((field, index) => {
											return (
												<th
													key={index}
													index={`th-${index}`}
													scope='col'
													className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
												>
													{field.text}
												</th>
											);
										})}
								</tr>
							</thead>

							<tbody className='bg-white divide-y divide-gray-200'>
								{/* First Row */}
								<tr>
									{fields?.length > 0 &&
										fields.map((field, index) => (
											<td key={`td-${index}`} className='px-6 py-4'>
												{field.type === 'image' ? (
													<div
														className={`inline-block animate-pulse bg-gray-400 rounded-full w-11 h-11`}
													/>
												) : (
													<div
														className={`animate-pulse h-4 bg-gray-200 rounded ${
															index === 0
																? 'w-full max-w-[100px]'
																: index === index - 1
																? 'w-full'
																: index === 1
																? 'w-full max-w-[130px]'
																: index === 2
																? 'w-full max-w-[170px]'
																: 'w-full max-w-[250px]'
														}`}
													/>
												)}
											</td>
										))}
								</tr>

								{/* Second Row */}
								<tr>
									{fields?.length > 0 &&
										fields.map((field, index) => (
											<td key={field.text} className='px-6 py-4'>
												{field.type === 'image' ? (
													<div
														className={`inline-block animate-pulse bg-gray-400 rounded-full w-11 h-11`}
													/>
												) : (
													<div
														className={`animate-pulse h-4 bg-gray-200 rounded ${
															index === 0
																? 'w-full max-w-[200px]'
																: index === index - 1
																? 'w-full'
																: index === 1
																? 'w-full max-w-[150px]'
																: index === 2
																? 'w-full max-w-[180px]'
																: 'w-full max-w-[100px]'
														}`}
													/>
												)}
											</td>
										))}
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableSkeletonLoader;
