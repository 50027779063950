import { makeId } from 'helpers/index';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiShuffle } from 'react-icons/bi';

import Button from '../ui/Button';
import CustomerContext from '../../context/customers/customerContext';

const CreateCustomerForm = ({ currentCustomer }) => {
	const customerContext = useContext(CustomerContext);
	const { createCustomer, customerLoading } = customerContext;

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		formState: { errors },
	} = useForm({ defaultValues: currentCustomer });

	const generateReferenceNumber = (e) => {
		e.preventDefault();
		const refNo = makeId(10);
		setValue('refNo', `exp-${refNo}`.toUpperCase());
	};

	const onSubmit = (values) => {
		values.email = values.email.toLowerCase();

		return createCustomer(values);

		if (currentCustomer) {
			// return dispatch(updateCustomer(values));
			return console.log('Updating customer...');
		}

		// > Validate passwords
		if (values?.password !== values?.confirm_password) {
			setError('password', {
				type: 'manual',
				message: 'Passwords do not match',
			});
			return setError('confirm_password', {
				type: 'manual',
				message: 'Passwords do not match',
			});
		} else {
		}
	};

	return (
		<form
			className='flex items-center justify-start px-4 py-2 text-left form'
			onSubmit={handleSubmit(onSubmit)}
			noValidate
			autoComplete='off'
		>
			<div className='w-full form__wrapper'>
				<h1
					className={`text-xl font-semibold text-opacity-80 text-primary-700`}
				>
					{currentCustomer ? 'Update Customer Detail' : 'Create Customer'}
				</h1>

				<p className='pt-2 pb-4 text-primary-700 text-opacity-60'>
					{currentCustomer
						? 'Please fill this with the new customer details.'
						: 'Add customer information.'}
				</p>

				<div className='grid gap-3 sm:grid-cols-2'>
					<div className='form-group'>
						<label htmlFor='firstName'>Firstname</label>
						<input
							autoComplete='off'
							id='first_name'
							type='text'
							{...register('first_name', {
								required: {
									value: true,
									message: 'You must enter your firstname.',
								},
							})}
							className={`${errors.first_name ? 'ring-2 ring-red-400' : null}`}
						/>
						{errors?.first_name && (
							<span className='mr-1 text-red-400'>
								{errors?.first_name?.message}
							</span>
						)}
					</div>

					<div className='form-group'>
						<label htmlFor='last_name'>Lastname</label>
						<input
							autoComplete='off'
							id='last_name'
							type='text'
							{...register('last_name', {
								required: {
									value: true,
									message: 'You must enter your lastname.',
								},
							})}
							className={`${errors.last_name ? 'ring-2 ring-red-400' : null}`}
						/>
						{errors?.last_name && (
							<span className='mr-1 text-red-400'>
								{errors?.last_name?.message}
							</span>
						)}
					</div>
				</div>

				<div className='form-group'>
					<label htmlFor='email'>E-Mail</label>
					<input
						autoComplete='off'
						type='email'
						id='email'
						{...register('email', {
							required: {
								value: true,
								message: 'You must enter your email.',
							},
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: 'Please enter a valid email',
							},
						})}
						className={`${errors.email ? 'ring-2 ring-red-400' : null}`}
					/>
					{errors?.email && (
						<span className='mr-1 text-red-400'>{errors?.email?.message}</span>
					)}
				</div>

				<div className='form-group'>
					<label htmlFor='phone'>Phone Number</label>
					<input
						autoComplete='off'
						type='text'
						id='mobileNumber'
						{...register('mobileNumber', {
							required: {
								value: true,
								message: "You must enter customer's mobile number.",
							},
							pattern: {
								value:
									/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
								message: 'Please enter a valid input.',
							},
						})}
						className={`${errors.mobileNumber ? 'ring-2 ring-red-400' : null}`}
					/>
					{errors?.mobileNumber && (
						<span className='mr-1 text-red-400'>
							{errors?.mobileNumber?.message}
						</span>
					)}
				</div>

				{!currentCustomer && (
					<>
						{/* <div className='grid gap-3 sm:grid-cols-2'> */}
						<div className='hidden form-group'>
							<label htmlFor='password'>Password</label>
							<input
								autoComplete='off'
								type='password'
								id='password'
								value={'password'}
								{...register('password', {
									required: {
										value: true,
										message: 'You must enter your password.',
									},
								})}
								className={`${errors.password ? 'ring-2 ring-red-400' : null}`}
							/>
							{errors?.password && (
								<span className='mr-1 text-red-400'>
									{errors?.password?.message}
								</span>
							)}
						</div>

						{/* <div className='form-group'>
							<label htmlFor='confirm-password'>Confirm Password</label>
							<input
								autoComplete='off'
								type='password'
								id='confirm-password'
								{...register('confirm_password', {
									required: {
										value: true,
										message: 'You must confirm your password.',
									},
								})}
								className={`${errors.password ? 'ring-2 ring-red-400' : null}`}
							/>
							<span className='mr-1 text-red-400'>
								{errors?.password?.message}
							</span>
						</div> */}
						{/* </div> */}
					</>
				)}

				{/* Auto Generate Reference */}
				<div className='mt-1'>
					<label
						htmlFor='toggler'
						className='block text-sm font-medium leading-7 capitalize text-primary-500/80'
					>
						Generate Reference Number
					</label>

					<div className='flex items-center w-full group'>
						<input
							type='text'
							className='w-full h-[48px] outline-none px-2 border-2 border-yellow-700/70 rounded-l-md  border-r-0'
							disabled
							{...register('refNo', {
								required: {
									value: true,
									message: 'Please generate a reference number.',
								},
							})}
						/>
						<Button
							type='button'
							textContent='Generate'
							className='text-base text-gray-100 duration-300 h-[48px] capitalize border-2 border-yellow-700/70 border-l-0 !rounded-r-md !rounded-l-none'
							bgClassName={'bg-yellow-700/70 hover:bg-gold-700'}
							onClick={generateReferenceNumber}
						>
							<BiShuffle className='text-2xl font-bold text-white' />
						</Button>
					</div>
					{errors?.refNo && (
						<span className='mr-1 text-red-400'>{errors?.refNo?.message}</span>
					)}
				</div>

				<div className='form-group'>
					<label htmlFor='location'>Location</label>
					<input
						autoComplete='off'
						type='text'
						{...register('location')}
						className={`${errors.location ? 'ring-2 ring-red-400' : null}`}
					/>
					{errors?.location && (
						<span className='mr-1 text-red-400'>
							{errors?.location?.message}
						</span>
					)}
				</div>

				<div className='form-group'>
					<label htmlFor='emergencyNumber'>Emergency Number</label>
					<input
						autoComplete='off'
						type='text'
						{...register('emergencyNumber', {
							pattern: {
								value: /^[0-9]/i,
								message: 'Only numbers are accepted for this field.',
							},
						})}
						className={`${
							errors.emergencyNumber ? 'ring-2 ring-red-400' : null
						}`}
					/>
					{errors?.emergencyNumber && (
						<span className='mr-1 text-red-400'>
							{errors?.emergencyNumber?.message}
						</span>
					)}
				</div>

				{/* <div className='form-group'>
					<label htmlFor=''>Profile Image</label>
					<input
						autoComplete='off'
						placeholder='Select image file'
						{...register('profilePic')}
						className={`${
							errors.profilePic ? 'ring-2 ring-red-400' : null
						} form-control block w-full px-3 py-1.5 text-base font-normal bg-clip-padding rounded transition ease-in-out m-0`}
						type='file'
						id='profilePic'
					/>
					{errors?.profilePic && (
						<span className='mr-1 text-red-400'>
							{errors?.profilePic?.message}
						</span>
					)}
				</div> */}

				<div className='flex justify-end pt-8'>
					<Button
						type='submit'
						textContent={
							currentCustomer ? 'Update Customer' : 'Create Customer'
						}
						className='text-base text-gray-100 capitalize rounded-md'
						bgClassName={`${
							currentCustomer ? 'bg-yellow-700 bg-opacity-70' : 'bg-blue-700'
						}`}
					>
						{/* {customersLoading && (
            <BiLoaderCircle className='text-2xl font-bold text-white animate-spin' />
          )} */}
					</Button>
				</div>
			</div>
		</form>
	);
};

export default CreateCustomerForm;
