import { useContext, useEffect } from 'react';
import { convertDate } from '../../helpers';

import Table from '../../components/Tables/Default';
import Card from '../../components/ui/Card';
import TransactionContext from '../../context/transactions/transactionContext';
import TableSkeletonLoader from 'components/ui/TableSkeletonLoader';
import EmptyResourceIndicator from 'components/ui/EmptyResourceIndicator';
import { useAuth } from 'context/auth/AuthState';

const Credits = () => {
	const transactionContext = useContext(TransactionContext);
	const [authState, authDispatch] = useAuth();
	const { user } = authState;
	const {
		credits,
		filteredTransactions,
		getTransactions,
		transactionsLoading,
	} = transactionContext;

	useEffect(() => {
		getTransactions(user?.is_admin == 0 ? user.id : null);
		// eslint-disable-next-line
	}, []);

	const buildTableBody = (transactionList) => {
		return transactionList.map((item) => {
			const {
				user: { first_name, last_name, email, refNo, image },
				amount,
				trans_status,
				created_at,
			} = item;

			return {
				refNo,
				imageText: {
					text: `${first_name} ${last_name}`,
					image: image,
				},
				email,
				credit: amount,
				date: convertDate(created_at),
			};
		});
	};

	return (
		<div>
			<Card className='flex flex-col h-full'>
				<div className='flex items-center justify-between w-full p-4 py-4'>
					<h1 className='text-xl font-semibold text-green-700'>
						Your Credit History
					</h1>
				</div>

				{transactionsLoading && !credits ? (
					<TableSkeletonLoader
						fields={[
							{ type: 'text', text: 'Ref #' },
							{ type: 'image', text: 'Customer' },
							{ type: 'text', text: 'email' },
							{ type: 'text', text: 'Credit' },
							{ type: 'text', text: 'Date' },
						]}
					/>
				) : credits?.data?.length > 0 ? (
					<Table
						headings={[
							{ text: 'Ref #' },
							{
								text: 'Image',
								type: 'imageText',
							},
							{
								text: 'Email',
								style: 'font-normal hover:underline',
							},
							{ text: 'Credit', style: 'text-green-700' },
							{ text: 'Date' },
						]}
						body={buildTableBody(credits.data)}
					/>
				) : (
					<EmptyResourceIndicator message={'No credits found'} />
				)}
			</Card>
		</div>
	);
};

export default Credits;
